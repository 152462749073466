import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { Documento } from './documento.model';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Pessoa } from '../pessoas/pessoa.model';
import { EnumTipoPessoa } from 'app/main/shared/models/tipo-pessoa.enum';
import { PessoaDocumentoDto } from 'app/main/shared/models/pessoa-dto.model';

@Injectable({
    providedIn: 'root'
})
export class DocumentoService extends BaseService<Documento> {
    constructor(protected injector: Injector) {
        super(
            '/api/documentos',
            injector,
            Documento.prototype,
            Documento.fromJson
        );
    }

    getByPessoaId(pessoa: Pessoa): Observable<Documento[]> {
        const url = `${this.urlResource}/pessoa/${pessoa.id}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    createMultipart(
        documento: Documento,
        multipartFile: Blob
    ): Observable<Documento> {
        return this.http
            .post(
                this.urlResource,
                this.createFormData(documento, multipartFile)
            )
            .pipe(
                map(Documento.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    updateMultipart(
        documento: Documento,
        multipartFile: Blob
    ): Observable<Documento> {
        const url = `${this.urlResource}/${documento.id}`;
        return this.http
            .put(url, this.createFormData(documento, multipartFile))
            .pipe(
                map(() => documento),
                catchError(this.handleError.bind(this))
            );
    }

    private createFormData(
        documento: Documento,
        multipartFile: Blob
    ): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        const documentoParam = new Blob([JSON.stringify(documento)], {
            type: 'application/json'
        });
        formData.append('documento', documentoParam);
        return formData;
    }

    deleteArquivo(id: number): Observable<Documento[]> {
        const url = `${this.urlResource}/${id}/arquivo`;
        return this.http
            .delete(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    criaDocumentosPessoa(pessoa: Pessoa, tipoPessoa: EnumTipoPessoa): Observable<Documento[]> {
        const pessoaDocumentoDto: PessoaDocumentoDto = new PessoaDocumentoDto(pessoa, tipoPessoa);
        const url = `${this.urlResource}/cria-documentos-pessoa`;
        return this.http
            .post(url, pessoaDocumentoDto)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
