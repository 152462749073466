import { Component, Inject, OnInit, Input, Injector } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Gestao } from '../../gestao.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'http';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { OrganizacaoAdministrativaService } from '../organizacao-administrativa.service';
import { OrganizacaoAdministrativa } from '../organizacao-administrativa.model';
import { GestaoService } from '../../gestao.service';
import { GestaoListComponent } from '../../gestao-list/gestao-list.component';
import { ConfirmarExclusaoDialogComponent } from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';

interface OrganizacaoAdministrativaNode {
    name: string;
    organizacaoAdministrativa: OrganizacaoAdministrativa;
    children?: OrganizacaoAdministrativaNode[];
}

interface FlatNode {
    expandable: boolean;
    name: string;
    level: number;
    organizacaoAdministrativa: OrganizacaoAdministrativa;
}

@Component({
    selector: 'app-organizacao-administrativa-tree',
    templateUrl: './organizacao-administrativa-tree.component.html',
    styleUrls: ['./organizacao-administrativa-tree.component.scss']
})
export class OrganizacaoAdministrativaTreeComponent implements OnInit {

    public static getUriComponent(gestaoId: number): string {
        return '/organizacoes-administrativas' +
            '/gestao' +
            '/' +
            gestaoId +
            '/tree';
    }

    // TODO Add temporariamente para resolver erros de build -prod, corrigir/remover.
    text: any;
    search(): void{ }
    // -----------------------------

    organizacoes: OrganizacaoAdministrativa[];
    organizacoesFiltered: OrganizacaoAdministrativa[];
    organizacao: OrganizacaoAdministrativa = null;
    organizacaoParentAdd: OrganizacaoAdministrativa = null;
    gestao: Gestao = null;
    protected router: Router;

    constructor(
        protected injector: Injector,
        protected organizacaoAdministrativaService: OrganizacaoAdministrativaService,
        protected gestaoService: GestaoService,
        protected dialog: MatDialog
    ) {
        const activatedRoute = this.injector.get(ActivatedRoute);
        this.router = this.injector.get(Router);
        activatedRoute.data.subscribe(source => {
            this.organizacoes = source.data as OrganizacaoAdministrativa[];
            this.loadTree();
            activatedRoute.params.subscribe(params => {
                this.loadGestao(params.id as number);
            });
        });
    }

    ngOnInit(): void {
        console.log(this.dataSource)
    }

    voltar(): void {
        // Voltar para a listagem de gestões
        this.router
            .navigateByUrl(GestaoListComponent.getComponentURI, { skipLocationChange: false });
    }

    public onDetailChange($event): void {
        if ($event && $event === 'salvar') {
            this.realoadTree();
        }
    }

    public filterChanged(filterText: string): void {
        if (filterText) {
            this.dataSource.data = this.getNestedChildren(this.organizacoes, 0, filterText);
            this.treeControl.expandAll();
        } else {
            this.treeControl.collapseAll();
            this.dataSource.data = this.getNestedChildren(this.organizacoes, 0);
        }
    }

    public onEdit(organizacao: OrganizacaoAdministrativa): void {
        if (organizacao) {
            this.organizacao = organizacao;
        }
    }

    public getOrganizacaoFromTree(organizacaoId: number, organizacoes: OrganizacaoAdministrativaNode[]): OrganizacaoAdministrativaNode {
        for (let i = 0, len = organizacoes.length; i < len; i++) {
            if (organizacoes[i].organizacaoAdministrativa.id === organizacaoId) {
                return organizacoes[i];
            }
            if (organizacoes[i].children) {
                const o = this.getOrganizacaoFromTree(organizacaoId, organizacoes[i].children);
                if (o) { return o; }
            }
        }
    }

    public getOChildrenIds(organizacao: OrganizacaoAdministrativaNode): string[] {
        const ids = [];
        if (organizacao.children) {
            for (let i = 0, len = organizacao.children.length; i < len; i++) {
                ids.push(organizacao.children[i].organizacaoAdministrativa.id);
                if (organizacao.children[i].children) {
                    const o = this.getOChildrenIds(organizacao.children[i]);
                    if (o) { ids.push(...o); }
                }
            }
        }
        return ids;
    }

    public onDelete(organizacao: OrganizacaoAdministrativa): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: organizacao.nome}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                // recupera organizacao pela tree
                const organizacaoNode = this.getOrganizacaoFromTree(organizacao.id, this.dataSource.data);
                // recupera array com ids dos filhos
                const childrenIds = this.getOChildrenIds(organizacaoNode) as string[];

                this.organizacaoAdministrativaService
                    .deleteWithChildrens(organizacao.id, childrenIds)
                    .subscribe(e => this.realoadTree());
            }
        });
    }

    public onAdd(organizacao: OrganizacaoAdministrativa): void {
        // Adicionando apenas o id e organizacaoAdministrativaPai para utilização em detail.
        this.organizacao =
            new OrganizacaoAdministrativa(
                organizacao ? organizacao.id : null
                , null
                , null
                , organizacao
            );
    }

    private loadTree(): void {
        if (this.organizacoes.length > 0) {
            const nestedTreeNodes: OrganizacaoAdministrativaNode[] = this.getNestedChildren(this.organizacoes, 0);
            this.dataSource.data = nestedTreeNodes;
        }
    }

    private realoadTree(): void {
        this.organizacaoAdministrativaService
            .getAllByGestao(this.gestao.id)
            .subscribe(organizacoes => {
                this.organizacoes = organizacoes;
                this.loadTree();
                this.organizacao = null; // reset organização
                this.treeControl.expandAll();
            });
    }

    private loadGestao(gestaoId: number): void {
        this.gestaoService.getById(gestaoId).subscribe(gestao => {
            this.gestao = gestao;
        });
    }

    private getNestedChildren(arr: OrganizacaoAdministrativa[], parentId, searchedText: string = null): OrganizacaoAdministrativaNode[] {
        const nested: OrganizacaoAdministrativaNode[] = [];
        for (let i = 0, len = arr.length; i < len; i++) {
            const id = (arr[i].organizacaoAdministrativaPai &&
                arr[i].organizacaoAdministrativaPai.id) ?
                arr[i].organizacaoAdministrativaPai.id : 0;
            if ( id === parentId ) {
                const children = this.getNestedChildren(arr, arr[i].id, searchedText);
                const organizacaoNode: OrganizacaoAdministrativaNode = {
                    name: arr[i].nome,
                    organizacaoAdministrativa: arr[i]
                };
                if ( children.length ) {

                    if (searchedText) {
                        for (let z = children.length - 1; z >= 0; z--) {
                            // Verifica se o item atual contém o nome e 
                            // se não possui filhos (já foram deletados) ou se possuir, é porque algum filho possui o termo.
                            // na validação do filho, validando se não existe (undefined) ou se existe e o array é vazio.
                            if (
                                children[z].name &&
                                !children[z].name.toLocaleLowerCase().includes(searchedText.toLocaleLowerCase()) &&
                                (!children[z].children || (children[z].children && !children[z].children.length))
                            ) {
                                children.splice(z, 1);
                            }
                        }
                    }

                    organizacaoNode.children = children;
                }
                nested.push(organizacaoNode);
            }
        }
        return nested;
    }

    private _transformer = (node: OrganizacaoAdministrativaNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
            organizacaoAdministrativa: node.organizacaoAdministrativa
        };
    }

    treeControl = new FlatTreeControl<FlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: FlatNode) => node.expandable;

}
