import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { PerfilSeap } from 'app/main/pages/servidores/servidor.model';

@Injectable({
    providedIn: 'root'
})
export class PerfilSeapService extends BaseService<PerfilSeap> {
    constructor(protected injector: Injector) {
        super('/api/perfil-seap', injector, PerfilSeap.prototype, PerfilSeap.fromJson);
    }
}
