import {Pais} from './../../paises/pais.model';
import {PaisService} from './../../paises/pais.service';
import {ENUM_UF_VALUES, EnumUf} from 'app/main/shared/models/uf.enum';
import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {CoreMessageService, ISEVERITY} from 'app/main/core/services/core-message.service';
import {Contato} from '../../contato/contatos.model';
import {Pessoa} from '../../pessoas/pessoa.model';
import * as moment from 'moment';
import { PessoaService } from '../../pessoas/pessoa.service';
import { EnumTipoPessoa } from 'app/main/shared/models/tipo-pessoa.enum';
import {DialogAjudaCtfComponent} from '../dialog-ajuda-ctf/dialog-ajuda-ctf.component';
import {MatDialog} from '@angular/material';
import {PessoaEstrangeira} from '../pessoa-estrangeira.model';
import {PessoaEstrangeiraService} from '../pessoa-estrangeira.service';

@Component({
    selector: 'app-pessoa-estrangeira-detail',
    templateUrl: './pessoa-estrangeira-datail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class PessoaEstrangeiraDetailComponent
    extends BaseDetailComponent<PessoaEstrangeira> implements OnInit {

    maxDate = moment().subtract(16, 'years');
    minDate = moment().subtract(120, 'years');
    ufValues = ENUM_UF_VALUES;
    paises: Pais[];
    compareWith = Pais.compareWith;

    dadosPrincipal: boolean;
    enderecoValidacao: boolean;
    telefoneValidacao: boolean;
    emailValidacao: boolean;
    profissaoValidacao: boolean;
    documentoValidacao: boolean;
    tipoPessoa: EnumTipoPessoa = EnumTipoPessoa.PF;

    constructor(
        protected pessoaEstrangeiraService: PessoaEstrangeiraService,
        protected pessoaService: PessoaService,
        protected paisService: PaisService,
        protected injector: Injector,
        protected messageService: CoreMessageService,
        protected dialog: MatDialog,
    ) {
        super(
            injector,
            new PessoaEstrangeira(),
            pessoaEstrangeiraService,
            PessoaEstrangeira.fromJson
        );
    }

    ngOnInit(): any {
        super.ngOnInit();
        this.loadPaises();
        this.dadosPrincipal = this.form.status === 'VALID';
        setTimeout(() => {
            if (this.enderecoValidacao && this.telefoneValidacao
                && this.emailValidacao && this.dadosPrincipal) {
                this.tornarCadastroCompleto(this.resource.pessoa);
            } else {
                this.tornarCadastroIncompleto(this.resource.pessoa);
            }
        }, 6000);
    }

    public validaCadastro(event: any, aba: string): void {
        this.validacaoRedIcon(event, aba);
    }

    private tornarCadastroIncompleto(pessoa: Pessoa): void {
        this.pessoaService.tornarCadastroIncompleto(pessoa).subscribe(() => {}, (e) => {
            this.snackBarService.showError(
                'Não foi possível realizar operação.', e);
        });
    }

    private tornarCadastroCompleto(pessoa: Pessoa): void {
        this.pessoaService.atualizarStatusCadastro(pessoa).subscribe(() => {}, (e) => {
            this.snackBarService.showError(
                'Não foi possível realizar operação.', e);
        });
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            cpf: [null],
            passaporte: [null, [Validators.required]],
            nome: [null, [Validators.required, Validators.maxLength(200)]],
            mae: [null, [Validators.required, Validators.maxLength(200)]],
            pai: [null, Validators.maxLength(200)],
            naturalidade: [null, [Validators.required, Validators.maxLength(200)]],
            nomeSocial: [null, [Validators.maxLength(200)]],
            dataNascimento: [
                {value: null, disabled: this.isSomenteVisualizacao}
            ],
            sexo: [{value: null, disabled: this.isSomenteVisualizacao}],
            estadoCivil: [
                {value: null, disabled: this.isSomenteVisualizacao}
            ],
            pessoa: this.formBuilder.group({
                id: [null],
                ativo: [{value: false, disabled: this.isSomenteVisualizacao}]
            }),
            rg: [null, [Validators.required, Validators.maxLength(20)]],
            rgOrgaoEmissor: [null, [Validators.required, Validators.maxLength(20)]],
            rgUfEmitente: [{value: null, disabled: this.isSomenteVisualizacao}],
            pais: [
                {value: null, disabled: this.isSomenteVisualizacao}
            ],
            cadastroTecnicoFederal: [{value: null, disabled: this.isSomenteVisualizacao}]
        });
    }

    get dataNascimento(): any {
        return this.form.get('dataNascimento');
    }

    protected actionsForError(response: any): any {
        if (response.error && response.error.errors) {
            response.error.errors.forEach(e => {
                if (e.split(':').length > 1) {
                    this.messageService.add({
                        severity: ISEVERITY.WARN,
                        message: 'Atenção',
                        description: e.split(':')[1]
                    });
                } else {
                    this.messageService.add({
                        message: 'Erro',
                        description: e
                    });
                }
            });
        }
    }

    protected creationPageTitle(): string {
        return 'Cadastro de nova pessoa estrangeira';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Editando a pessoa: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Visualizando a pessoa: ' + resourceName;
    }

    get sexos(): any {
        return PessoaEstrangeira.sexos;
    }

    get estadosCivis(): any {
        return PessoaEstrangeira.estadosCivis;
    }

    get contato(): Contato {
        return this.resource.pessoa.contato;
    }

    get pessoaEstrangeira(): PessoaEstrangeira {
        return this.resource;
    }

    get pessoa(): Pessoa {
        return this.resource.pessoa;
    }

    getUfTexto(tipo: string): string {
        return EnumUf[tipo];
    }

    private loadPaises(): any {
        this.paisService
            .getAll()
            .subscribe(paises => {
                this.paises = paises;
            });
    }

    private validacaoRedIcon(status: any, aba: string): void {
        switch (aba) {
            case 'ENDERECO':
                this.enderecoValidacao = status === 'VALID';
                break;
            case 'TELEFONE':
                this.telefoneValidacao = status === 'VALID';
                break;
            case 'EMAIL':
                this.emailValidacao = status === 'VALID';
                break;
            case 'PROFISSAO':
                this.profissaoValidacao = status === 'VALID';
                break;
            case 'DOCUMENTO':
                this.documentoValidacao = status === 'VALID';
                break;
        }
    }

    abrirAjudaCtf(): void {
        this.dialog.open(DialogAjudaCtfComponent, {
            width: '40%',
        });
    }
}
