import { BaseModel } from 'app/main/shared/models/base.model';

export class TipoCategoria extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public dataInicial?: Date,
        public dataFim?: Date,
    ) {
        super();
    }

    static fromJson(json: any): TipoCategoria {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoCategoria(
            json.id,
            json.descricao,
            json.dataInicial,
            json.dataFim
        );
    }
}
