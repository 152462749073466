import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { ResponsavelTecnico } from 'app/main/pages/responsaveis-tecnico/responsavel-tecnico.model';
import { appendFile } from 'fs';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';

@Component({
    selector: 'app-confirmar-alteracao-idalog',
    templateUrl: './confirmar-alteracao-idalog.component.html',
})
export class ConfirmarAlteracaoIdalogComponent implements OnInit {
    form: FormGroup;
    dataSource: MatTableDataSource<ResponsavelTecnico>;
    dataSourceAnexo: MatTableDataSource<any>;
    displayedColumnsAnexo: string[] = ['titulo', 'acao'];
    nomeArquivoSelecionado: string = '';
    anexoSalvo: any[] = [];
    idAnexo: number = 0;
    arquivoAnexoSelecionado: Boolean = false;
    file: File;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService
    ) {
        this.dataSource = new MatTableDataSource<ResponsavelTecnico>([]);
        this.dataSourceAnexo = new MatTableDataSource<any>([]);
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            justificativa: [
                null,
                [Validators.required, Validators.minLength(1)],
            ],
            habilitado: [null, [Validators.required]],
            tituloDocumentoAnexo: ['', [Validators.required]],
            arquivo: ['', [Validators.required]],
            idTemp: [null],
        });
        this.form.patchValue({
            habilitado: !this.data.responsavelTecnico.ativo,
        });
        this.dataSource.data = [this.data.responsavelTecnico];
        this.dataSourceAnexo.data = [this.data.any];
        this.limparTabelaArquivos();
    }

    onFileSelect(event): void {
        if (!event.target.files[0].size) {
            this.snackBarService.showAlert('Arquivo com tamanho inválido.');
            document.getElementById('input-arquivo')['value'] = null;
            return;
        }
        if (event.target.files.length === 1) {
            this.file = event.target.files[0];
            this.arquivoAnexoSelecionado = true;
            this.preencherCampoTituloDocumentoAnexo(this.file);
            this.anexarArquivo();
        } else {
            this.form.get('arquivo').reset();
        }
    }

    anexarArquivo() {      
        if (this.arquivoAnexoSelecionado) {
          this.anexoSalvo = [];
            this.anexoSalvo.push({
                id: null,
                idTemp: this.idAnexo,
                arquivo: this.form.controls.arquivo.value,
                tituloDocumentoAnexo:
                    this.form.controls.tituloDocumentoAnexo.value,
            });
            this.idAnexo++;
            this.dataSourceAnexo = new MatTableDataSource<any>(this.anexoSalvo);
        }
        this.arquivoAnexoSelecionado = false;
    }

    limparTabelaArquivos() {
        this.anexoSalvo = [];
        this.dataSourceAnexo = new MatTableDataSource<any>([]);
        this.form.get('arquivo').reset();
        this.form.get('tituloDocumentoAnexo').reset();
        document.getElementById('input-arquivo')['value'] = null;
        this.arquivoAnexoSelecionado = false;
    }

    abrirPdf(idAnexo?: number, file?: any) {
        if (idAnexo) {
            //window.open(`${this.solicitacaoAnexoService.urlResource}/visualizar-doc/${idAnexo}`);
        } else {
            window.open(
                URL.createObjectURL(
                    new Blob([file], { type: 'application/pdf' })
                )
            );
        }
    }

    preencherCampoTituloDocumentoAnexo(file: File) {
        let tituloComExtensao = file.name;
        let indexExcluido = tituloComExtensao.indexOf('.pdf');
        let tituloSemExtensao = tituloComExtensao.substring(0, indexExcluido);
        this.form.get('tituloDocumentoAnexo').setValue(tituloSemExtensao);
        this.form.get('arquivo').setValue(this.file);
    }
}
