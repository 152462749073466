import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from 'app/main/shared/shared.module';
import {CoreModule} from 'app/main/core/core.module';
import {MatTooltipModule} from '@angular/material/tooltip';

import { PortalSecurityAuthGuardPerfilService } from '@sema-security-angular/portal-security';
import { DialogAjudaCtfComponent } from './dialog-ajuda-ctf/dialog-ajuda-ctf.component';
import { PessoaEstrangeiraDetailComponent } from './pessoa-estrangeira-datail/pessoa-estrangeira-datail.component';
import { PessoaEstrangeiraListResolver, PessoaEstrangeiraResolver } from './pessoa-estrangeira.service';
import { PessoaEstrangeiraListComponent } from './pessoa-estrangeira-list/pessoa-estrangeira-list.component';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";


export const routes: Routes = [
    {
        path: 'new',
        component: PessoaEstrangeiraDetailComponent,
        canActivate: [ PortalSecurityAuthGuardPerfilService ],
        data: {
            securityId: ['10b3e96b-aa59-40a6-a133-eb36ff3eb995'],
            securityMessage: 'Acesso negado.'
        }
    },
    {
        path: 'pessoas-estrangeiras',
        component: PessoaEstrangeiraListComponent,
        resolve: {
            data: PessoaEstrangeiraListResolver
        }
    },
    {
        path: ':id/edit',
        component: PessoaEstrangeiraDetailComponent,
        canActivate: [ PortalSecurityAuthGuardPerfilService],
        resolve: {
            data: PessoaEstrangeiraResolver
        },
        data: {
            securityId: ['10b3e96b-aa59-40a6-a133-eb36ff3eb995'],
            securityMessage: 'Acesso negado.'
        }
    },
    {
        path: ':id/detail',
        component: PessoaEstrangeiraDetailComponent,
        resolve: {
            data: PessoaEstrangeiraResolver
        }
    }
];

@NgModule({
    declarations: [PessoaEstrangeiraListComponent, PessoaEstrangeiraDetailComponent, DialogAjudaCtfComponent, PessoaEstrangeiraListComponent],
    imports: [CoreModule, SharedModule, RouterModule.forChild(routes), MatTooltipModule],
    entryComponents: [PessoaEstrangeiraDetailComponent, PessoaEstrangeiraListComponent, DialogAjudaCtfComponent],
    providers: [PessoaEstrangeiraResolver, PessoaEstrangeiraListResolver],
    exports: [DialogAjudaCtfComponent]
})
export class PessoasEstrangeirasModule {
}
