import {
    ChangeDetectorRef,
    Component,
    Injector,
    ViewEncapsulation
} from '@angular/core';

import {HttpParams} from '@angular/common/http';
import {fuseAnimations} from '@fuse/animations';
import {BaseListComponent} from 'app/main/shared/components/base-list/base-list.component';
import {ServidorService} from '../servidor.service';
import {Servidor} from '../servidor.model';

const displayedColumns: string[] = [
    'id',
    'matricula',
    'cpf',
    'nome',
    'cargo',
    'ativo'
];

@Component({
    selector: 'app-servidor-list',
    templateUrl: './servidor-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ServidorListComponent extends BaseListComponent<Servidor> {
    descricao: string = '';

    constructor(
        injector: Injector,
        service: ServidorService
    ) {
        super(injector, displayedColumns, service);
    }

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);

        return httpParams;
    }


    ativar(id: number): void {
        (this.service as ServidorService).ativar(id).subscribe(() => {
            this.snackBarService.showSuccess('Registro ativado com sucesso.');
            this.list(0);
        }, e => {
            this.snackBarService.showError('Não foi possível ativar o registro.', e);
        });
    }
}