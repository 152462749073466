import { OnInit, AfterContentChecked, Injector } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { BaseModel } from '../../models/base.model';
import { BaseService } from '../../services/base.service';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { ConfirmarExclusaoDialogComponent } from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import { ApiError } from '../../models/api-error';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';

export declare type CrudAction = 'new' | 'edit' | 'detail';

export abstract class BaseDetailComponent<T extends BaseModel> implements OnInit, AfterContentChecked {
    
    protected activatedRoute: ActivatedRoute;
    protected router: Router;
    protected formBuilder: FormBuilder;
    protected snackBarService: SnackBarService;
    protected routingStateService: RoutingStateService;
    protected dialog: MatDialog;

    currentAction: CrudAction;
    form: FormGroup;
    pageTitle: string;
    submittingForm = false;
    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    constructor(
        protected injector: Injector,
        public resource: T,
        protected service: BaseService<T>,
        protected jsonDataToResourceFn: (jsonData: any) => T
    ) {
        this.activatedRoute = this.injector.get(ActivatedRoute);
        this.router = this.injector.get(Router);
        this.formBuilder = this.injector.get(FormBuilder);
        this.snackBarService = this.injector.get(SnackBarService);
        this.routingStateService = this.injector.get(RoutingStateService);
        this.dialog = injector.get(MatDialog);
    }

    ngOnInit(): void {
        this.setCurrentAction();
        this.buildResourceForm();
        this.loadResource();
    }

    ngAfterContentChecked(): void {
        this.setPageTitle();
    }

    get isSomenteVisualizacao(): boolean {
        return this.currentAction === 'detail';
    }

    salvar(): void {
        this.submittingForm = true;

        if (this.currentAction === 'new') {
            this.createResource();
        } else if (this.currentAction === 'edit') {
            this.updateResource();
        }
    }

    voltar(): void {
        this.router
            .navigateByUrl(this.getBaseComponetPath(), { skipLocationChange: true })
            .then(() => this.router.navigate([this.getBaseComponetPath()], { skipLocationChange: true }));
    }

    limpar(): void {
        this.router
            .navigateByUrl(this.getBaseComponetPath(), { skipLocationChange: true })
            .then(() =>
                this.router.navigate([this.getBaseComponetPath(), 'new'], { skipLocationChange: true })
            );
    }

    excluirDialog(): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {
                label: this.resource.id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluir();
            }
        });
    }

    excluir(): void {
        this.service.delete(this.resource.id).subscribe(
            (response: any) => {
                this.snackBarService.showSuccess('Registro excluido com sucesso.');
                this.voltar();
            },
            e => {
                this.snackBarService.showError(
                    'Não foi possível excluir o registro',e
                );
            }
        );
    }

    protected getBaseComponetPath(): string {
        return this.activatedRoute.snapshot.parent.url[0].path;
    }

    protected setCurrentAction(): void {
        if (this.activatedRoute.snapshot.url[0] != null && this.activatedRoute.snapshot.url[0].path === 'new') {
            this.currentAction = 'new';
        } else if (this.activatedRoute.snapshot.url[1] != null && this.activatedRoute.snapshot.url[1].path === 'edit') {
            this.currentAction = 'edit';
        } else {
            this.currentAction = 'detail';
        }
    }

    protected loadResource(): void {
        if (this.currentAction !== 'new') {
            this.activatedRoute.data.subscribe(response => {
                this.resource = response.data;
                this.form.patchValue(this.resource);
                this.afterResourceLoad();
            });
        }
    }

    protected setPageTitle(): void {
        if (this.currentAction === 'new') {
            this.pageTitle = this.creationPageTitle();
        } else if (this.currentAction === 'edit') {
            this.pageTitle = this.editionPageTitle();
        } else {
            this.pageTitle = this.visualizationPageTitle();
        }
    }

    protected creationPageTitle(): string {
        return 'Novo';
    }

    protected editionPageTitle(): string {
        return 'Edição';
    }

    protected visualizationPageTitle(): string {
        return 'Visualização';
    }

    protected createResource(): void {
        const res: T = this.jsonDataToResourceFn(this.form.value);
        console.log(res)
        this.service.create(res).subscribe(
            resource => {
                return this.actionsForSuccess(resource);
            },
            error => this.actionsForError(error)
        );
    }

    protected updateResource(): void {
        const res: T = this.jsonDataToResourceFn(this.form.value);
        this.service.update(res).subscribe(
            resource => this.actionsForSuccess(resource),
            error => this.actionsForError(error)
        );
    }

    protected actionsForSuccess(resource: T): void {
        this.snackBarService.showSuccess('Solicitação processada com sucesso!');

        this.router
            .navigateByUrl(this.getBaseComponetPath(), {
                skipLocationChange: true
            })
            .then(() =>
                this.router.navigate([
                    this.getBaseComponetPath(),
                    resource.id,
                    'edit'
                ], { skipLocationChange: true })
            );
    }

    protected actionsForError(e: any): void {
        this.snackBarService.showError('Erro ao processar a solicitação', e.error);

        this.submittingForm = false;
    }

    protected abstract buildResourceForm(): void;

    protected afterResourceLoad(): void {}
}
