import { Component } from '@angular/core';
import { Injector, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { HttpParams } from '@angular/common/http';
import { BaseListComponent } from '../../../shared/components/base-list/base-list.component';
import { Router } from '@angular/router';
import { TipoEspecialidade } from '../tipo-especialidade.model';
import { TipoEspecialidadeService } from '../tipo-especialidade.service';

@Component({
    selector: 'app-tipo-especialidade-list',
    templateUrl: './tipo-especialidade-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class TipoEspecialidadeListComponent extends BaseListComponent<TipoEspecialidade> {
    constructor(injector: Injector, service: TipoEspecialidadeService) {
        super(injector, ['id', 'descricao', 'buttons'], service);
        this.router = this.injector.get(Router);
    }

    public static readonly getComponentURI = '/categorias';

    descricao = '';
    protected router: Router;

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);
        return httpParams;
    }
}
