import {HttpParams} from '@angular/common/http';
import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {BaseListComponent} from 'app/main/shared/components/base-list/base-list.component';
import {PessoaEstrangeira} from '../pessoa-estrangeira.model';
import {PessoaEstrangeiraService} from '../pessoa-estrangeira.service';

@Component({
    selector: 'app-pessoa-estrangeira-list',
    templateUrl: './pessoa-estrangeira-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class PessoaEstrangeiraListComponent extends BaseListComponent<PessoaEstrangeira> {

    descricao = '';

    constructor(injector: Injector, service: PessoaEstrangeiraService) {
        super(injector, ['id', 'passaporte', 'nome', 'nomeSocial', 'ativo'], service);
    }

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);
        return httpParams;
    }
}
