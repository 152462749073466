import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseDetailComponent } from 'app/main/shared/components/base-detail/base-detail.component';
import { TipoCategoriaService } from 'app/main/pages/tipo-categoria/tipo-categoria.service';
import { TipoCategoria } from '../tipo-categoria.model';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'app-tipo-categoria',
    templateUrl: './tipo-categoria.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class TipoCategoriaComponent extends BaseDetailComponent<TipoCategoria> implements OnInit {

  categoria: TipoCategoria;

    constructor(
        protected injector: Injector,
        protected tipoCategoriaService: TipoCategoriaService
    ) {
        super(
            injector,
            new TipoCategoria(),
            tipoCategoriaService,
            TipoCategoria.fromJson
        );
    }

    ngOnInit() {
      super.ngOnInit();      
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            descricao:[null, [Validators.required, Validators.max(100)]],
            dataInicial: [null],
            dataFim: [null]
        });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de tipo de categoria';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Editando o tipo de categoria: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Visualizando o tipo de categoria: ' + resourceName;
    }

    protected actionsForSuccess(): void {
        this.snackBarService.showSuccess('Solicitação processada com sucesso!');

        this.router
            .navigateByUrl(this.getBaseComponetPath(), {
                skipLocationChange: true
            })           
    } 
}
