import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Pessoa} from '../../../pages/pessoas/pessoa.model';
import {PessoaFisicaService} from '../../../pages/pessoas-fisicas/pessoa-fisica.service';
import {PessoaFisica} from '../../../pages/pessoas-fisicas/pessoa-fisica.model';
import {Responsavel} from '../../../pages/responsaveis/responsavel.model';
import {BaseModel} from '../../models/base.model';
import {ResponsavelService} from '../../../pages/responsaveis/responsavel.service';
import {ActivatedRoute} from '@angular/router';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PessoaService} from '../../../pages/pessoas/pessoa.service';
import {SnackBarService} from '../../services/snack-bar/snack-bar.service';
import {DialogEditarLotacaoPfPjComponent} from "./dialog-editar-lotacao-pf-pj/dialog-editar-lotacao-pf-pj.component";


@Component({
    selector: 'app-responsaveis',
    templateUrl: './responsaveis.html',
    styleUrls: ['./responsaveis.component.scss']
})
export class ResponsaveisComponent implements OnInit {
    @Input() pessoa: Pessoa;
    @Input() validacaoFormulario: boolean;
    @Output() responsaveisFormValidate = new EventEmitter();

    resultadoConsultaPessoa: PessoaFisica [];
    listaDeResponsaveis: Responsavel[];
    cpfControl: FormControl;
    tipoLotacaoControl: FormControl;
    tiposRepresentantes = Responsavel.tiposRepresentante;
    representanteColumns = ['nome', 'cpf'];
    representanteAtribuidosColumns = ['cpf', 'nome', 'tipoRepresentante', 'ativo'];

    ngOnInit(): any {
        this.buildResponsaveisForm();
        this.resultadoConsultaPessoa = [];
        this.listaDeResponsaveis = [];
        this.loadRepresentantes();
        this.representanteAtribuidosColumns = this.isSomenteVisualizacao ?
            ['cpf', 'nome', 'tipoRepresentante', 'habilitado'] : ['cpf', 'nome', 'tipoRepresentante', 'habilitado', 'acoes'];
    }

    constructor(
        protected formBuilder: FormBuilder,
        protected pessoaFisicaService: PessoaFisicaService,
        protected pessoaService: PessoaService,
        protected responsavelService: ResponsavelService,
        protected activatedRoute: ActivatedRoute,
        protected snackBarService: SnackBarService,
        protected dialog: MatDialog
    ) {

    }

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    public buildResponsaveisForm(): void {
        this.cpfControl = this.formBuilder.control(null, Validators.required);
        this.tipoLotacaoControl = this.formBuilder.control(null, Validators.required);
    }

    public limparCampos(): void {
        this.cpfControl.reset();
        this.tipoLotacaoControl.reset();
        this.resultadoConsultaPessoa = [];
    }

    public pesquisarResponsavel(): void {
        this.cpfControl.markAllAsTouched();
        if (this.cpfControl.valid) {
            if (this.listaDeResponsaveis.some(item => item.representante.cpf === this.cpfControl.value)) {
                this.snackBarService.showAlert('Esse responsável já está vinculado');
            } else {
                this.pessoaFisicaService.getByCpfAndCadastroCompleto(this.cpfControl.value).subscribe(pessoaFisica => {
                    this.resultadoConsultaPessoa = [pessoaFisica];
                }, e => this.snackBarService.showError(e.error.errors[0] === '' ? e.error.message : e.error.errors[0]));
            }
        } else {
            this.snackBarService.showAlert('Digite o CPF corretamente');
        }
    }

    public addRepresentante(): void {
        this.tipoLotacaoControl.markAllAsTouched();
        if (this.tipoLotacaoControl.valid) {
            this.responsavelService
                .create(Responsavel.fromJson({
                    tipoRepresentante: this.tipoLotacaoControl.value,
                    representanteId: this.resultadoConsultaPessoa[0].id,
                    representadoId: this.pessoa.id
                })).subscribe(
                () => {
                    this.snackBarService.showSuccess('Resposável  adicionado com sucesso!');
                    this.loadRepresentantes();
                    this.limparCampos();
                },
                error => {
                    this.snackBarService.showError('Erro ao adicionar resposável.', error);
                    this.limparCampos();
                }
            );
        }
    }

    public loadRepresentantes(): void {
        this.responsavelService
            .getRepresentantes(this.pessoa.id)
            .subscribe(listaDeResponsaveis => {
                this.listaDeResponsaveis = listaDeResponsaveis;
                if (this.listaDeResponsaveis.length > 0) {
                    this.responsaveisFormValidate.emit('VALID');
                } else {
                    this.responsaveisFormValidate.emit('NO_VALID');
                }
            });
    }

    public get isSomenteVisualizacao(): boolean {
        return this.activatedRoute.snapshot.url[1].path === 'detail';
    }

    public removeRepresentanteDialog(responsavel: Responsavel): void {

        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: responsavel.nome}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.responsavelService.desativaRepresentante(responsavel.representado.id, responsavel.representante.id).subscribe(
                    () => {
                        this.snackBarService.showSuccess('Representante desabilitado com sucesso!');
                        this.loadRepresentantes();
                    },
                    (e) => this.snackBarService.showError('Erro ao desabilitar representante.', e)
                );
            }
        });

    }

    public concluirCadastro(): void {
        this.pessoaService.atualizarStatusCadastro(this.pessoa).subscribe(() => {
            this.pessoa.cadastroCompleto = true;
            this.snackBarService.showSuccess('Cadastro finalizado com sucesso !');

        }, (erro) => {
            this.snackBarService.showError('Erro ao realizar cadastro !');
        });

    }

    habilitarDesabilitarResponsavel(item: Responsavel): void {
        if (item.ativo) {
            this.removeRepresentanteDialog(item);
        } else {
            this.responsavelService.ativaRepresentante(item.representado.id, item.representante.id).subscribe(() => {
                this.loadRepresentantes();
            }, e => this.snackBarService.showError(e));
        }
    }

    editarLotacao(item: Responsavel): void {
        const dialogRef = this.dialog.open(DialogEditarLotacaoPfPjComponent, {data: Object.assign( new Responsavel(), item)});
        dialogRef.afterClosed().subscribe(result => {
            if (result){
                this.loadRepresentantes();
            }
        });
    }
}


