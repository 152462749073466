import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ServidorEvento } from '../../servidor.model';
import { ServidorService } from '../../servidor.service';
import { SnackBarService } from 'app/main/shared/services/snack-bar/snack-bar.service';


@Component({
    templateUrl: 'dialog-servidor-evento.component.html',
    styleUrls: ['dialog-servidor-evento.component.scss'],
})

export class DialogServidorEventoComponent implements OnInit {

    form: FormGroup;

    maxDate: any;
    isSomenteVisualizacao: any;

    constructor(private dialogRef: MatDialogRef<DialogServidorEventoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ServidorEvento,
                private formBuilder: FormBuilder,
                protected servidorService: ServidorService,
                protected snackBarService: SnackBarService
    ) {

    }

    ngOnInit(): void {
        if(this.data) {
            this.form = this.formBuilder.group(this.returnObjectForm());
        }
    }

    private returnObjectForm(): any {
        return {
            eventoId: this.data.id,
            eventoDescricao: this.data.eventoDescricao,
            tipoLicenca: [this.data.tipoLicenca],
            dataInicioEvento: [this.data.dataInicio],
            dataFimEvento: [this.data.dataFim],
            indReducaoCargaHoraria: [this.data.indReducaoCargaHoraria],
            indBloqueado: [this.data.indBloqueado]
        };
    }

    salvarEvento() {
        const evento: ServidorEvento = {
            id: this.form.get('eventoId').value,
            eventoDescricao: this.form.get('eventoDescricao').value,
            tipoLicenca: this.form.get('tipoLicenca').value,
            dataInicio: this.form.get('dataInicioEvento').value,
            dataFim: this.form.get('dataFimEvento').value,
            indReducaoCargaHoraria: this.form.get('indReducaoCargaHoraria').value,
            indBloqueado: this.form.get('indBloqueado').value
        };
        this.servidorService
                .updateEvento(this.data.servidor, evento)
                .subscribe(
                    _ => {
                        this.dialogRef.close(true)
                    },
                    error => this.snackBarService.showError(error)
                );
    }

    public closeDialog(): void {
        this.dialogRef.close(false);
    }
}
