import { Component, Injector, OnInit } from '@angular/core';
import {PessoaJuridicaService} from 'app/main/pages/pessoa-juridica/pessoa-juridica.service';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {Validators} from '@angular/forms';
import {PessoaJuridica} from 'app/main/pages/pessoa-juridica/pessoa-juridica.model';
import {Contato} from '../../contato/contatos.model';
import {Pessoa} from '../../pessoas/pessoa.model';
import { PessoaService } from '../../pessoas/pessoa.service';
import { EnumTipoPessoa } from 'app/main/shared/models/tipo-pessoa.enum';
import {DialogAjudaCtfComponent} from '../../pessoas-fisicas/dialog-ajuda-ctf/dialog-ajuda-ctf.component';

@Component({
    selector: 'app-pessoa-juridica-detail',
    templateUrl: './pessoa-juridica-detail.component.html',
})
export class PessoaJuridicaDetailComponent extends BaseDetailComponent<PessoaJuridica> implements OnInit {

    maxDate = new Date();
    public pessoaJuridica: any;
    dadosPrincipal: boolean;
    enderecoValidacao: boolean;
    telefoneValidacao: boolean;
    emailValidacao: boolean;
    documentoValidacao: boolean;
    atividadeValidacao: boolean;

    tipoPessoa: EnumTipoPessoa = EnumTipoPessoa.PJ;

    constructor(
        protected pessoaJuridicaService: PessoaJuridicaService,
        protected pessoaService: PessoaService,
        protected injector: Injector
    ) {
        super(
            injector,
            new PessoaJuridica(),
            pessoaJuridicaService,
            PessoaJuridica.fromJson
        );

    }

    ngOnInit(): void {
        super.ngOnInit();
        this.dadosPrincipal = this.form.status === 'VALID';
        setTimeout(() => {
            if(this.enderecoValidacao && this.telefoneValidacao 
                && this.emailValidacao && this.documentoValidacao 
                && this.atividadeValidacao && this.dadosPrincipal) {
                    this.tornarCadastroCompleto(this.resource.pessoa);
                } else {
                    this.tornarCadastroImcompleto(this.resource.pessoa);
                }
        }, 6000)
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            ativo: [{value: null, disabled: this.isSomenteVisualizacao}],
            cnpj: [{value: null, disabled: this.isSomenteVisualizacao}, [Validators.required]],
            nomeFantasia: [null, [Validators.required, Validators.maxLength(200)]],
            razaoSocial: [null, [Validators.required, Validators.maxLength(200)]],
            inscricaoMunicipal: [null, [Validators.required, Validators.maxLength(30)]],
            inscricaoEstadual: [null, [Validators.required, Validators.maxLength(30)]],
            naturezaJuridica: [null, [Validators.required, Validators.maxLength(80)]],
            porteEmpresa: [null, [Validators.required, Validators.maxLength(10)]],
            dataConstituicao: [null, [Validators.required]],
            dataCriacao: [null],
            pessoa: this.formBuilder.group({
                ativo: [
                    {value: null, disabled: this.isSomenteVisualizacao}
                ],
            }),
            cadastroTecnicoFederal: [{value: null, disabled: this.isSomenteVisualizacao}, [Validators.maxLength(200)]],
        });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de nova Pessoa Jurídica';
    }

    protected editionPageTitle(): string {
        const resourceName =
            this.resource.razaoSocial ||
            this.resource.nomeFantasia ||
            'Nova empresa';
        return 'Editando: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName =
            this.resource.razaoSocial ||
            this.resource.nomeFantasia ||
            'Nova empresa';
        return 'Visualizando: ' + resourceName;
    }

    get contato(): Contato {
        return this.resource.pessoa.contato;
    }

    get pessoa(): Pessoa {
        return this.resource.pessoa;
    }

    private validacaoRedIcon(status: any, aba: string): void {
        switch (aba) {
            case 'ENDERECO':
                this.enderecoValidacao = status === 'VALID';
                break;
            case 'TELEFONE':
                this.telefoneValidacao = status === 'VALID';
                break;
            case 'EMAIL':
                this.emailValidacao = status === 'VALID';
                break;
            case 'DOCUMENTO':
                this.documentoValidacao = status === 'VALID';
                break;
            case 'ATIVIDADE':
                this.atividadeValidacao = status === 'VALID';
                break;
        }
    }

    salvar(): void {
        this.submittingForm = true;
        this.form.markAllAsTouched();
        if (this.form.valid) {
            if (this.currentAction === 'new') {
                this.createResource();
            } else if (this.currentAction === 'edit') {
                this.updateResource();
            }
        }
    }

    public validaCadastro(event: any, aba: string) {
        this.validacaoRedIcon(event, aba);
    }

    private tornarCadastroImcompleto(pessoa: Pessoa) {
        this.pessoaService.tornarCadastroIncompleto(pessoa).subscribe(() => {}, (e) => {
            this.snackBarService.showError(
                'Não foi possível realizar operação.', e);
        });
    }

    private tornarCadastroCompleto(pessoa: Pessoa) {
        this.pessoaService.atualizarStatusCadastro(pessoa).subscribe(() => {}, (e) => {
            this.snackBarService.showError(
                'Não foi possível realizar operação.', e);
        });
    }

    abrirAjudaCtf(){
        this.dialog.open(DialogAjudaCtfComponent, {
            width: '40%',
        });
    }
}
