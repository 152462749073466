import { HttpParams } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { BaseListComponent } from 'app/main/shared/components/base-list/base-list.component';
import { ConfirmarAlteracaoIdalogComponent } from 'app/main/shared/components/confirmar-alteracao-idalog/confirmar-alteracao-idalog.component';
import { AuthenticatedUserService } from 'app/main/shared/services/authenticated-user.service';
import { ResponsavelTecnico } from '../responsavel-tecnico.model';
import { ResponsavelTecnicoService } from '../responsavel-tecnico.service';

@Component({
    selector: 'app-responsavel-tecnico-list',
    templateUrl: './responsavel-tecnico-list.component.html',
    animations: fuseAnimations,
})
export class ResponsavelTecnicoListComponent
    extends BaseListComponent<ResponsavelTecnico>
    implements OnInit
{
    public descricao = '';
    public usuarioLogado;
    public dataFim: Date;
    public idRespTecnico: number;
    public file: File;
    

    constructor(
        injector: Injector,
        service: ResponsavelTecnicoService,
        private authenticatedUserService: AuthenticatedUserService
    ) {
        super(
            injector,
            [
                'id',
                'cpfCnpj',
                'nomeRazaoSocial',
                'tipoPessoa',
                'cadastroTecFederal',
                'conselho',
                'registroConselho',
                'ativo',
            ],
            service
        );
    }

    ngOnInit(): void {
        super.ngOnInit();       
            this.usuarioLogado = this.authenticatedUserService.identityValue;            
    }

    temPapel(papel: string): boolean{
        return this.authenticatedUserService.hasRole(papel);
    }    

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);
        return httpParams;
    }

    ativarDesativar(respTecnico: ResponsavelTecnico): any {
        this.idRespTecnico = respTecnico.id;

        const dialogRef = this.dialog.open(ConfirmarAlteracaoIdalogComponent, {
            width: '1800px',
            maxHeight: '600px',
            data: { responsavelTecnico: respTecnico },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const file: File = result.arquivo;
                (this.service as ResponsavelTecnicoService)
                    .ativarDesativar(this.idRespTecnico, file, result)
                    .subscribe(
                        () => {
                            this.snackBarService.showSuccess(
                                'Registro alterado com sucesso.'
                            );
                            this.list(0);
                        },
                        (e) => {
                            this.snackBarService.showAlert(
                                'Não foi possível alterar esse registro.'
                            );
                        }
                    );
            }
        });
    }
}
