import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { FuncaoServidor } from 'app/main/pages/servidores/funcao-servidor.model';

@Injectable({
    providedIn: 'root'
})
export class FuncaoServidorService extends BaseService<FuncaoServidor> {
    constructor(protected injector: Injector) {
        super('/api/funcao-servidor', injector, FuncaoServidor.prototype, FuncaoServidor.fromJson);
    }
}
