import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'app/main/shared/shared.module';
import { CoreModule } from 'app/main/core/core.module';
import { TipoCategoriaListComponent } from './tipo-categoria-list/tipo-categoria-list.component';
import { TipoCategoriaComponent } from './tipo-categoria/tipo-categoria.component';
import {
    TipoCategoriaListResolver,
    TipoCategoriaResolver,
} from './tipo-categoria.service';

export const routes: Routes = [
    {
        path: 'new',
        component: TipoCategoriaComponent,
    },
    {
        path: '',
        component: TipoCategoriaListComponent,
        resolve: {
            data: TipoCategoriaListResolver,
        },
    },
    {
        path: ':id/edit',
        component: TipoCategoriaComponent,
        resolve: {
            data: TipoCategoriaResolver,
        },
    },
    {
        path: ':id/detail',
        component: TipoCategoriaComponent,
        resolve: {
            data: TipoCategoriaResolver,
        },
    },
];

@NgModule({
    declarations: [TipoCategoriaComponent, TipoCategoriaListComponent],
    imports: [
        RouterModule.forChild(routes),
        CoreModule,
        SharedModule,
    ],
    entryComponents: [TipoCategoriaComponent, TipoCategoriaListComponent],
    providers: [TipoCategoriaResolver, TipoCategoriaListResolver],
})
export class TipoCategoriaModule {}
