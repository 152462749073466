import {BaseModel} from 'app/main/shared/models/base.model';
import { Pessoa } from 'app/main/pages/pessoas/pessoa.model';
import { EnumTipoPessoa } from './tipo-pessoa.enum';

export class PessoaDocumentoDto extends BaseModel {
    constructor(
        public pessoa: Pessoa,
        public tipoPessoa: EnumTipoPessoa
    ) {
        super();
    }

    static fromJson(json: any): PessoaDocumentoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaDocumentoDto(
            Pessoa.fromJson(json.pessoa),
            json.tipoPessoa
        );
    }

    static fromJsons(json: any): PessoaDocumentoDto[] {
        const pessoas: PessoaDocumentoDto[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(pessoa => pessoas.push(this.fromJson(pessoa)));
            return pessoas;
        }
    }

}
