import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {InjectionToken, NgModule} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatPaginatorIntl} from '@angular/material';
import {MatButtonModule} from '@angular/material/button';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {FuseProgressBarModule, FuseSidebarModule} from '@fuse/components';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {AppComponent} from 'app/app.component';
import {fuseConfig} from 'app/fuse-config';
import {LayoutModule} from 'app/layout/layout.module';
import {CoreComponentsModule} from 'app/main/core/components/core-components.module';
import {PageErrorsModule} from 'app/main/errors/page-errors.module';
import {environment} from 'environments/environment';
import 'hammerjs';
import {AuthenticationInterceptor} from './interceptors/authentication.interceptor';
import {CoreModule} from './main/core/core.module';
import {AccountService} from './main/core/services/account.service';
import {ISEVERITY} from './main/core/services/core-message.service';
import {Principal} from './main/core/services/principal.service';
import {RoutingStateService} from './main/core/services/routing-state.service';
import {UtilService} from './main/core/util/util.service';
import {PagesModule} from './main/pages/pages.module';
import {LoadingInterceptor} from './main/shared/components/loading/loading.interceptor';
import {LoadingModule} from './main/shared/components/loading/loading.module';
import {getPtBrPaginatorIntl} from './main/shared/utils/ptBr-paginator-intl';
import {MOMENT_DATE_FORMATS, MomentDateAdapter} from './moment-date-adapter';
import {PortalSecurityModule} from '@sema-security-angular/portal-security';

export const envSecurity = new InjectionToken<any>("ENV_SECURITY");
export const envSecurityMessageService = new InjectionToken<any>("ENV_SECURITY_MESSAGE_SERVICE");

export  function portalSecurityMessageService(msg, opt)  {
    UtilService.showMessage(msg,{ ...opt, severity: ISEVERITY.ERROR});
};


const appRoutes: Routes = [
    {
        path: '',
        loadChildren: './main/pages/pages.module#PagesModule'
    }
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterModule.forRoot(appRoutes, {useHash: true, enableTracing: false}),

        TranslateModule.forRoot(),

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,

        // App modules
        LayoutModule,
        PageErrorsModule,
        CoreModule.forRoot(),
        PortalSecurityModule.forRoot(environment, {
            URL_SECURITY_PORTAL_API: environment.URL_PORTAL_API,
            URL_SECURITY_ENDPOINT_USER_INFO: '/api/auth/me',
            URL_SECURITY_ENDPOINT_PERFIS:'/api/perfis-usuario/me',
            URL_SECURITY_ENDPOINT_GRUPOS: '/api/grupos-usuario/me',
            URL_SECURITY_ENDPOINT_MENUS: '/api/menus/me',
            URL_SECURITY_ENDPOINT_OPERACOES: '/api/menus/me/operacoes/chaves',
            URL_SECURITY_ENDPOINT_USER_RESOURCES: '/api/perfis-usuario/me/uuids',
            URL_SECURITY_ENDPOINT_USER_RESOURCES_VALIDATE: '/api/perfis-usuario/me/uuids/validate',
            MESSAGE_FUNCTION: portalSecurityMessageService
        }),
        CoreComponentsModule,
        PagesModule,
        MatTooltipModule,
        LoadingModule
    ],
    exports: [PagesModule, CoreComponentsModule],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
        {provide: MatPaginatorIntl, useValue: getPtBrPaginatorIntl()},
        {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
        {provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS},
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' }},
        RoutingStateService,
        AccountService,
        Principal
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor() {
        console.log('[scorp] ambiente de ' + environment.name);
    }
}
