import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { LanguageHelper } from 'app/main/shared/services/language-helper.service';
import * as moment from 'moment';
import { CoreMessageService, ISEVERITY } from '../services/core-message.service';


@Injectable()
export class UtilService {
    static messageService: CoreMessageService;
    
    constructor(private languageHelper: LanguageHelper, private injector: Injector, private msgService: CoreMessageService) {
        UtilService.messageService=msgService;
    }

    public static showMessage(msg:string, opt?: { severity?: ISEVERITY }) {
        CoreMessageService.INSTANCE.add({message:msg, severity: opt && opt.severity ? opt.severity : ISEVERITY.WARN});
    }

    public static getGuid(): string {
        return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    verificaLimiteAdicionar(total, addQuantidade, limite) {
        if (total + addQuantidade > limite) {
            return true;
        }
        return false;
    }

    dataURItoBlob(dataURI) {
        // code adapted from: http://stackoverflow.com/questions/33486352/cant-upload-image-to-aws-s3-from-ionic-camera
        const binary = atob(dataURI.split(',')[1]);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
    }
    toDataUrl(url, callback) {
          const xhr = new XMLHttpRequest();
          xhr.onload = function() {
              const reader = new FileReader();
              reader.onloadend = function() {
                  callback(reader.result);
              }
              reader.readAsDataURL(xhr.response);
          };
          xhr.open('GET', url);
          xhr.responseType = 'blob';
          xhr.send();
    }

    strToZonedDateTime(date: string, fromFormat: string, lang?: any) {
        if (date === undefined || date === null)
            return null;

        if (!lang)
            lang = this.languageHelper.getService().getDefaultLang();
        const data = moment(date, fromFormat, true);
        const formatDate =  data.format('YYYY-MM-DDTHH:mm:ss.[001][Z]'); // data.toISOString();
        // let formatDate = data.utc().format();
        return formatDate;
    }

    zonedDateTimeFormat(date: string, format: string, lang?: any) {
        if (date === undefined || date === null)
            return null;
        if (!lang)
            lang = this.languageHelper.getService().getDefaultLang();
        const data = moment.parseZone(date);
        const formatDate = data.format(format);
        return formatDate;
    }
    dateTimeFormat(date: string, fromFormat: string, toFormat: string, lang?: any) {
        if (date === undefined || date === null)
            return null;
        if (!lang)
            lang = this.languageHelper.getService().getDefaultLang();
        const data =  moment(date, fromFormat, true);  // moment.parseZone(date);
        const formatDate = data.format(toFormat);
        return formatDate;
    }

    nowZonedDateTime() {
        return moment().format('YYYY-MM-DDTHH:mm:ss.[001][Z]');
    }
    
    /**
     * Get a random floating point number between `min` and `max`.
     * 
     * @param {number} min - min number
     * @param {number} max - max number
     * @return {number} a random floating point number
     */
    getRandomFloat(min, max) {
      return Math.random() * (max - min) + min;
    }

    /**
     * Get a random integer between `min` and `max`.
     * 
     * @param {number} min - min number
     * @param {number} max - max number
     * @return {number} a random integer
     */
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }

    /**
     * Get a random boolean value.
     * 
     * @return {boolean} a random true/false
     */
    getRandomBool() {
      return Math.random() >= 0.5;
    }
    

    /**
     * ARAYS
     */
    findObjectByKey(array, key, value) {
        if (array !== null)
        for (let i = 0; i < array.length; i++) {
            if (array[i][key] === value) {
                return array[i];
            }
        }
        return null;
    }

//     xmlToJson(xmlString: string, cb) {
//        return  xml2js.xmlParseString(xmlString, function (err, result) {
//            if (err)
//                return null;
//            cb(result);
//            return JSON.stringify(result);
//        });
//    }
    
    formataCodigoVenda(venda, codigo?:string) {
        let str:string = '';
        if (codigo && (venda == null || venda == undefined)) {
            str = codigo;
            // VENDA PRESENCIAL / COMISSARIO / BILHETERIA
            str = str.replace(/(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,2})/g, function(a, b, c, d, e){
                let ret = "";
                if(b != "")
                    ret = b;
                if(c != "")
                    ret = ret+"." + c;
                if(d != "")
                    ret = ret+"." + d;
                if(e != "")
                    ret = ret+"-" + e;
                return ret;
          });
            // if (str != null && str.startsWith('V'))
            return str;
            

        }
        
        if (venda!=null) {
            if (venda.sessionId != null) {
                str = venda.sessionId;
                if ((<string>venda.sessionId).startsWith('V')) {
                    //let str:string = venda.sessionId;
                    // VENDA PRESENCIAL / COMISSARIO / BILHETERIA
                    str = str.replace(/(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,2})/g, function(a, b, c, d, e){
                        let ret = "";
                        if(b != "")
                            ret = b;
                        if(c != "")
                            ret = ret+"." + c;
                        if(d != "")
                            ret = ret+"." + d;
                        if(e != "")
                            ret = ret+"-" + e;
                        return ret;
                  });
                    venda.sessionIdFormatted =  str;
                   
                }
            }
        }
        
        return  str;
    }
    
    // remove  . , - e qualquer caractere especial
    
    formataCodigoVendaOriginal(codigoVenda: string) {
        let codVenda: string = codigoVenda;
        return codVenda.split(/[a-z,A-Z,0-9]/gi).join();
    }
    formataUsuarioPapel(valor?:string) {
        
        if (valor !== null) {
            let r: string = valor.toUpperCase();
            if (r.indexOf('ROLE_USER') > -1) return ''; // desconsidera papel usuario (nao necessario)
            if (r.indexOf('ROLE_') > -1) {
                return ( r.substr(r.indexOf('ROLE_') + 'ROLE_'.length, r.length)).replace(/\_/gi,'-');
            }
        }
        return valor;
    }
    
}
