import {Injectable, Injector} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {Servidor, ServidorEvento, ServidorLotacao} from './servidor.model';

@Injectable()
export class ServidorListResolver extends ListResolver<Servidor> {
    constructor(service: ServidorService) {
        super(service);
    }
}

@Injectable()
export class ServidorResolver extends DetailResolver<Servidor> {
    constructor(service: ServidorService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({providedIn: 'root'})
export class ServidorService extends BaseService<Servidor> {
    constructor(protected injector: Injector) {
        super(
            '/api/servidores',
            injector,
            Servidor.prototype,
            Servidor.fromJson
        );
    }

    createLotacao(servidor: Servidor, lotacao: ServidorLotacao): Observable<ServidorLotacao> {
        const url = `${this.urlResource}/${servidor.id}/lotacao`;

        return this.http
        .post(url, lotacao)
        .pipe(
            map(ServidorLotacao.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    ativar(id: number): Observable<void> {
        const url = `${this.urlResource}/${id}/ativar`;

        return this.http.post<void>(url, null);
    }

    deleteLotacao(lotacao: ServidorLotacao): Observable<any> {
        const url = `${this.urlResource}/${lotacao.servidorId}/lotacao/${lotacao.id}`;

        return this.http
        .delete(url)
        .pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    updateEvento(servidor: Servidor, evento: ServidorEvento): Observable<ServidorEvento> {
        const url = `${this.urlResource}/${servidor.id}/evento`;

        return this.http
        .put(url, evento)
        .pipe(
            map(ServidorEvento.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    deleteEvento(evento: ServidorEvento): Observable<any> {
        const url = `${this.urlResource}/${evento.servidor.id}/evento/${evento.id}`;

        return this.http
        .delete(url)
        .pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    getLotacoes(servidor: Servidor): Observable<ServidorLotacao[]> {
        const url = `${this.urlResource}/${servidor.id}/lotacoes`;

        return this.http
        .get(url)
        .pipe(
            map(ServidorLotacao.fromIterable.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    getEventos(servidor: Servidor): Observable<ServidorEvento[]> {
        const url = `${this.urlResource}/${servidor.id}/eventos`;

        return this.http
        .get(url)
        .pipe(
            map(ServidorEvento.fromIterable.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    getByCpf(cpf: string): Observable<Servidor> {
        const value = cpf.match(/\d+/g).join('');
        const url = `${this.urlResource}/cpf/${value}`;

        return this.http
        .get(url)
        .pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    /* TODO
    * Ver o objeto de retorno no SEAP para criar model
    */
    getSeapByCpf(cpf: string): Observable<Servidor> {
        const value = cpf.match(/\d+/g).join('');
        const url = `${this.urlResource}/consulta-seap/cpf/${value}`;

        return this.http
        .get(url)
        .pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    protected handleError(error: any): Observable<any> {
        if (error) {
            if (error.status === 404) {
                return of(null);
            }

            if (error.error && error.error.message) {
                error.toString = () => error.error.message;
            }
        }

        return super.handleError(error);
    }

    servidorFindById(idServidor: number): Observable<any> {
        return this.http
        .get(`${this.urlResource}/${idServidor}`)
        .pipe(
            map(response => response),
            catchError(e => throwError(e))
        );
    }

    getAllServidorPorIdOrganizacao(idOrganizacao: number): Observable<any> {
        return this.http
        .get(`${this.urlResource}/busca-serivdor-organizacao/${idOrganizacao}`)
        .pipe(
            map(response => response),
            catchError(e => throwError(e))
        );
    }

    getAllServidorPorListaOrganizacao(listaId: any): Observable<any> {
        return this.http.post(`${this.urlResource}/buscar-servidor-organizacao`, listaId).pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }
}
