import { Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseDetailComponent } from 'app/main/shared/components/base-detail/base-detail.component';
import { TipoEspecialidade } from '../tipo-especialidade.model';
import { TipoEspecialidadeService } from '../tipo-especialidade.service';

@Component({
    selector: 'app-tipo-especialidade',
    templateUrl: './tipo-especialidade.component.html',
    styleUrls: ['./tipo-especialidade.component.scss'],
})
export class TipoEspecialidadeComponent extends BaseDetailComponent<TipoEspecialidade> implements OnInit {

  categoria: TipoEspecialidade;

    constructor(
        protected injector: Injector,
        protected tipoEspecialidadeService: TipoEspecialidadeService
    ) {
        super(
            injector,
            new TipoEspecialidade(),
            tipoEspecialidadeService,
            TipoEspecialidade.fromJson
        );
    }

    ngOnInit() {
      super.ngOnInit();      
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            descricao:[null, [Validators.required, Validators.max(100)]],
            dataInicial: [null],
            dataFim: [null]
        });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de tipo de especialidade';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Editando o tipo de especialidade: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Visualizando o tipo de especialidade: ' + resourceName;
    }

    protected actionsForSuccess(): void {
        this.snackBarService.showSuccess('Solicitação processada com sucesso!');

        this.router
            .navigateByUrl(this.getBaseComponetPath(), {
                skipLocationChange: true
            })           
    }

 
}
