import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { ListResolver } from 'app/main/shared/resolvers/list-resolver';
import { DetailResolver } from 'app/main/shared/resolvers/detail-resolver';
import { TipoCategoria } from './tipo-categoria.model';

@Injectable()
export class TipoCategoriaListResolver extends ListResolver<TipoCategoria> {
    constructor(service: TipoCategoriaService) {
        super(service);
    }
}

@Injectable()
export class TipoCategoriaResolver extends DetailResolver<TipoCategoria> {
    constructor(
        service: TipoCategoriaService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root',
})
export class TipoCategoriaService extends BaseService<TipoCategoria> {
    constructor(protected injector: Injector) {
        super(
            '/api/tipo-categoria',
            injector,
            TipoCategoria.prototype,
            TipoCategoria.fromJson
        );
    }
}
