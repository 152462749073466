import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { ResponsavelTecnico } from './responsavel-tecnico.model';
import { ListResolver } from 'app/main/shared/resolvers/list-resolver';
import { DetailResolver } from 'app/main/shared/resolvers/detail-resolver';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HistoricoAlteracaoRtDTO } from 'app/main/shared/components/confirmar-alteracao-idalog/historico-alteracao-rt';

@Injectable()
export class ResponsavelTecnicoListResolver extends ListResolver<ResponsavelTecnico> {
    constructor(service: ResponsavelTecnicoService) {
        super(service);
    }
}

@Injectable()
export class ResponsavelTecnicoResolver extends DetailResolver<ResponsavelTecnico> {
    constructor(
        service: ResponsavelTecnicoService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root',
})
export class ResponsavelTecnicoService extends BaseService<ResponsavelTecnico> {
    constructor(protected injector: Injector) {
        super(
            '/api/responsaveis-tecnicos',
            injector,
            ResponsavelTecnico.prototype,
            ResponsavelTecnico.fromJson
        );
    }

    getByCpfCnpj(cpfCnpj: string): Observable<ResponsavelTecnico> {
        const url = `${this.urlResource}/cpf-cnpj/${cpfCnpj}`;
        return this.http
            .get(url)
            .pipe(
                map(ResponsavelTecnico.fromJson.bind(this), (e) =>
                    this.handleError(e)
                )
            );
    }

    ativarDesativar(idResposavelTecnico: number, file: File, historicoRT: HistoricoAlteracaoRtDTO): Observable<HistoricoAlteracaoRtDTO> {
        console.log('Enviando payload...', JSON.stringify(historicoRT));
        const url = `${this.urlResource}/ativar-desativar-rt-cadastro/${idResposavelTecnico}`;
        return this.http.post(url, this.montarFormData(file, historicoRT))            
            .pipe(map(HistoricoAlteracaoRtDTO.fromJson.bind(this)),
                catchError(this.handleError.bind(this)));
    }

    private montarFormData(multipartFile: Blob, historico: HistoricoAlteracaoRtDTO): FormData {
        const formData = new FormData();
        formData.append('file', multipartFile);
        const historicoRrParam = new Blob([JSON.stringify(historico)],
        {type: 'application/json'});
        formData.append('historicoAlteracaoResponsavelTecnico', historicoRrParam);
        return formData;
    }

}
