import {AfterViewInit, Injector, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {tap} from 'rxjs/operators';
import {BaseDataSource} from '../../models/base-datasource';
import {HttpParams} from '@angular/common/http';
import {createRequestOptions} from 'app/main/core/util/request-util';
import {BaseService} from '../../services/base.service';
import {BaseModel} from '../../models/base.model';
import {ActivatedRoute} from '@angular/router';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {MatDialog} from '@angular/material';
import {SnackBarService} from '../../services/snack-bar/snack-bar.service';

export abstract class BaseListComponent<T extends BaseModel> implements OnInit, AfterViewInit {

    activatedRoute: ActivatedRoute;
    dataSource: BaseDataSource<T>;
    protected snackBarService: SnackBarService;
    protected dialog: MatDialog;

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;

    @ViewChild(MatSort, {static: true})
    sorter: MatSort;

    constructor(
        protected injector: Injector,
        public displayedColumns: string[],
        protected service: BaseService<T>
    ) {
        this.activatedRoute = injector.get(ActivatedRoute);
        this.snackBarService = this.injector.get(SnackBarService);
        this.dialog = injector.get(MatDialog);
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(response => {
            this.dataSource = new BaseDataSource(
                this.service,
                this.paginator,
                this.sorter,
                response.data
            );
        });
    }

    ngAfterViewInit(): void {
        this.paginator.page.pipe(tap(() => this.list(this.paginator.pageIndex))).subscribe();
        this.sorter.sortChange
        .pipe(tap(() => this.dataSource.sort()))
        .subscribe();
    }

    list(pageIndex: number): void {
        let httpParams: HttpParams = createRequestOptions({
            page: String(pageIndex),
            size: String(this.paginator.pageSize)
        });
        httpParams = this.fillFilters(httpParams);
        this.dataSource.list(httpParams);
    }

    excluirDialog(resource: T): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: resource.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluir(resource);
            }
        });
    }

    private excluir(resource: T): void {
        this.service.delete(resource.id).subscribe(() => {
                this.snackBarService.showSuccess('Registro excluído com sucesso.');
                this.list(0);
            },
            e => {
                this.snackBarService.showError('Não foi possível excluir o registro.', e);
            }
        );
    }

    abstract fillFilters(httpParams: HttpParams): HttpParams;
}
