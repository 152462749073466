import {DocumentoService} from './../../../pages/documentos/documento.service';
import {DocumentoDialogComponent} from './../documento-dialog/documento-dialog.component';
import {Documento} from './../../../pages/documentos/documento.model';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Pessoa} from 'app/main/pages/pessoas/pessoa.model';
import {MatDialog} from '@angular/material';
import {DocumentoTemplate} from '../../../pages/documentos-template/documento-template.model';
import {SnackBarService} from '../../services/snack-bar/snack-bar.service';
import {ConfirmarExclusaoDialogComponent} from "../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component";
import { EnumTipoPessoa } from '../../models/tipo-pessoa.enum';

@Component({
    selector: 'app-documento',
    templateUrl: './documento.component.html',
    styleUrls: ['documento.component.scss']
})
export class DocumentoComponent implements OnInit {

    @Input() isSomenteVisualizacao: boolean;
    @Input() pessoa: Pessoa;
    @Input() tipoPessoa?: EnumTipoPessoa;
    @Output() documentoFormValidate = new EventEmitter();
    documentos: Documento[];

    constructor(
        protected documentoService: DocumentoService,
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService
    ) {
    }

    ngOnInit(): void {
        this.listarDocumentos();
    }

    public listarDocumentos(): void {
        this.documentoService
            .getByPessoaId(this.pessoa)
            .subscribe(documentos => {
                this.documentos = documentos || [];
                this.documentos = this.documentos.filter((d,i,a)=>a.findIndex(v2=>(v2.template.id===d.template.id))===i); // remove duplicados, deixando um documento por tipo (template)

                if(this.documentos.length > 0) {
                    this.documentoFormValidate.emit(documentos.every(item => item.opcional || item.chave != null) ? 'VALID' : 'NO_INVALID');
                } else {
                    if(this.tipoPessoa) {
                        this.criaDocumentos(this.pessoa, this.tipoPessoa);
                    }
                    this.documentoFormValidate.emit('NO_VALID');
                }
            });
    }

    public documentoDialog(documento?: Documento): void {
        const dialogRef = this.dialog.open(DocumentoDialogComponent, {
            width: '600px',
            data: documento
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.listarDocumentos();
            }
        });
    }

    updateDocumento(multipartFile, documento?: Documento): void {
        (documento.id === null
                ? this.documentoService.createMultipart(documento, multipartFile)
                : this.documentoService.updateMultipart(documento, multipartFile)
        ).subscribe(
            () => {
                this.listarDocumentos();
            },
            (e) => {
                this.snackBarService.showError(
                    'Não foi possível salvar o registro.', e);
            }
        );
    }

    public visualizarDocumento(documento: Documento): void {
        window.open(
            this.documentoService.urlResource +
            '/' +
            documento.id +
            '/visualizar'
        );
    }

    public downloadDocumento(documento: Documento): void {
        window.open(
            this.documentoService.urlResource + '/' + documento.id + '/download'
        );
    }

    public formatarLabelTamanhoDoc(tamanho, opcaoTamanho): string {
        if (opcaoTamanho === 'DOC') {
            DocumentoTemplate.tamanhosDocumento.forEach(t => {
                if (t.valor === tamanho) {
                    tamanho = t.descricao;
                }
            });
            return tamanho;
        }
        DocumentoTemplate.tamanhosPagina.forEach(t => {
            if (t.valor === tamanho) {
                tamanho = t.descricao;
            }
        });
        return tamanho;
    }

    public deletarDocumento(item: Documento): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: item.nome}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.documentoService.deleteArquivo(item.id).subscribe(() => {
                    this.snackBarService.showSuccess('Documento excluido com sucesso.');
                    this.listarDocumentos();
                });
            }
        });
    }

    private criaDocumentos(pessoa: Pessoa, tipoPessoa: EnumTipoPessoa) {
        this.documentoService.criaDocumentosPessoa(pessoa, tipoPessoa).subscribe(() => {
            this.listarDocumentos();
        });
    }
}
