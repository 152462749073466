import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { ListResolver } from 'app/main/shared/resolvers/list-resolver';
import { DetailResolver } from 'app/main/shared/resolvers/detail-resolver';
import { TipoEspecialidade } from './tipo-especialidade.model';

@Injectable()
export class TipoEspecialidadeListResolver extends ListResolver<TipoEspecialidade> {
    constructor(service: TipoEspecialidadeService) {
        super(service);
    }
}

@Injectable()
export class TipoEspecialidadeResolver extends DetailResolver<TipoEspecialidade> {
    constructor(
        service: TipoEspecialidadeService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root',
})
export class TipoEspecialidadeService extends BaseService<TipoEspecialidade> {
    constructor(protected injector: Injector) {
        super(
            '/api/tipo-especialidade',
            injector,
            TipoEspecialidade.prototype,
            TipoEspecialidade.fromJson
        );
    }
}
