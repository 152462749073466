import {BaseModel} from 'app/main/shared/models/base.model';
import * as moment from 'moment';

export class ServidorLotacao extends BaseModel {
    constructor(
        public id?: number,
        public servidorId?: number,
        public funcaoServidorId?: number,
        public organizacaoId?: number,
        public cargoDescricao?: string,
        public organizacaoNome?: string,
        public funcaoServidor?: string,
        public codUnidadeSeap?: string,
        public unidadeSeap?: string,
        public dataInicio?: string,
        public dataFim?: string,
        public ativo?: boolean
    ) {
        super();
    }

    static fromJson(json: any): ServidorLotacao {
        json = json || {};

        if (typeof json.dataInicio === 'string') {
            json.dataInicio = moment.utc(json.dataInicio).local().format('DD/MM/YYYY');
        }

        if (typeof json.dataFim === 'string') {
            json.dataFim = moment.utc(json.dataFim).local().format('DD/MM/YYYY');
        }

        return new ServidorLotacao(
            json.id,
            json.servidorId,
            json.funcaoServidorId,
            json.organizacaoId,
            json.cargoDescricao,
            json.organizacaoNome,
            json.funcaoServidor,
            json.codUnidadeSeap,
            json.unidadeSeap,
            json.dataInicio,
            json.dataFim,
            json.ativo
        );
    }

    static fromIterable(json: any): ServidorLotacao[] {
        const lotacoes: ServidorLotacao[] = [];

        if (json && typeof json[Symbol.iterator] === 'function') {
            for (const value of json) {
                if (value) {
                    lotacoes.push(ServidorLotacao.fromJson(value));
                }
            }
        }

        return lotacoes;
    }
}

export class ServidorEvento extends BaseModel {
    constructor(
        public id?: number,
        public servidor?: Servidor,
        public eventoDescricao?: string,
        public tipoLicenca?: string, 
        public indBloqueado?: boolean,
        public indReducaoCargaHoraria?: boolean,
        public dataInicio?: Date,
        public dataFim?: Date,
        public ativo?: boolean
    ) {
        super();
    }

    static fromJson(json: any): ServidorEvento {
        json = json || {};

        return new ServidorEvento(
            json.id,
            Servidor.fromJson(json.servidor),
            json.eventoDescricao,
            json.tipoLicenca,
            json.indBloqueado,
            json.indReducaoCargaHoraria,
            json.dataInicio,
            json.dataFim,
            json.ativo
        );
    }

    static fromIterable(json: any): ServidorEvento[] {
        const eventos: ServidorEvento[] = [];

        if (json && typeof json[Symbol.iterator] === 'function') {
            for (const value of json) {
                if (value) {
                    eventos.push(ServidorEvento.fromJson(value));
                }
            }
        }

        return eventos;
    }
}

export class PerfilSeap extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public codigoSeap?: number
    ) {
        super();
    }

    static fromJson(json: any): PerfilSeap {
        json = json || {};

        return new PerfilSeap(
            json.id,
            json.descricao,
            json.codigoSeap
        );
    }

    static fromIterable(json: any): PerfilSeap[] {
        const perfilSeap: PerfilSeap[] = [];

        if (json && typeof json[Symbol.iterator] === 'function') {
            for (const value of json) {
                if (value) {
                    perfilSeap.push(PerfilSeap.fromJson(value));
                }
            }
        }

        return perfilSeap;
    }
}

export class TipoVinculo extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string
    ) {
        super();
    }

    static fromJson(json: any): TipoVinculo {
        json = json || {};

        return new TipoVinculo(
            json.id,
            json.descricao
        );
    }

    static fromIterable(json: any): TipoVinculo[] {
        const tipoVinculo: TipoVinculo[] = [];

        if (json && typeof json[Symbol.iterator] === 'function') {
            for (const value of json) {
                if (value) {
                    tipoVinculo.push(TipoVinculo.fromJson(value));
                }
            }
        }

        return tipoVinculo;
    }
}

export class Servidor extends BaseModel {
    constructor(
        public id?: number,
        public matricula?: string,
        public cpf?: string,
        public rg?: string,
        public nome?: string,
        public nomeSocial?: string,
        public dataNascimento?: string,
        public cargoId?: number,
        public cargo?: string,
        public pessoaFisicaId?: number,
        public perfilSeapId?: number,
        public tipoVinculoId?: number,
        public tipoEspecialidadeId?: number,
        public tipoCategoriaId?: number,
        public profissoes?: string,
        public ativo?: boolean,
        public emails?: string[],
        public telefones?: string[],
        public emailServidor?: string) {
        super();
    }

    static fromJson(json: any): Servidor {
        json = json || {};

        if (typeof json.dataNascimento === 'string') {
            json.dataNascimento = moment.utc(json.dataNascimento).local().format('DD/MM/YYYY');
        }

        const id = typeof json.pessoa === 'object' ? 0 : json.id || 0;
        const cargoId = (json.cargo && typeof json.cargo === 'object') ? json.cargo.id : json.cargoId || 0;
        const cargo = (json.cargo && typeof json.cargo === 'object') ? json.cargo.descricao : json.cargo || '';
        const pessoaFisicaId = typeof json.pessoa === 'object' ? json.id : json.pessoaFisicaId || 0;

        return new Servidor(
            id,
            json.matricula,
            json.cpf,
            json.rg,
            json.nome,
            json.nomeSocial,
            json.dataNascimento,
            cargoId,
            cargo,
            pessoaFisicaId,
            json.perfilSeapId,
            json.tipoVinculoId,
            json.tipoEspecialidadeId,
            json.tipoCategoriaId,
            json.profissoes,
            json.ativo,
            json.emails,
            json.telefones,
            json.emailServidor
        );
    }
}
