import { Component } from '@angular/core';
import { Injector, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { HttpParams } from '@angular/common/http';
import { BaseListComponent } from '../../../shared/components/base-list/base-list.component';
import { Router } from '@angular/router';
import { TipoCategoria } from '../tipo-categoria.model'; 
import { TipoCategoriaService } from '../tipo-categoria.service';

@Component({
    selector: 'app-tipo-categoria-list',
    templateUrl: './tipo-categoria-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class TipoCategoriaListComponent extends BaseListComponent<TipoCategoria> {
    constructor(injector: Injector, service: TipoCategoriaService) {
        super(injector, ['id', 'descricao', 'buttons'], service);
        this.router = this.injector.get(Router);
    }

    descricao = '';
    protected router: Router;

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);
        return httpParams;
    }
}
