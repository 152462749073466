import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Responsavel} from '../../../../pages/responsaveis/responsavel.model';
import {ResponsavelService} from '../../../../pages/responsaveis/responsavel.service';
import {SnackBarService} from '../../../services/snack-bar/snack-bar.service';

@Component({
    selector: 'app-dialog-editar-lotacao-pf-pj',
    templateUrl: './dialog-editar-lotacao-pf-pj.component.html',
    styleUrls: ['./dialog-editar-lotacao-pf-pj.component.scss']
})
export class DialogEditarLotacaoPfPjComponent implements OnInit {

    tiposRepresentantes = Responsavel.tiposRepresentante;

    constructor(
        private dialogRef: MatDialogRef<DialogEditarLotacaoPfPjComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Responsavel,
        private responsavelService: ResponsavelService,
        private snackBarService: SnackBarService
    ) {
    }

    ngOnInit(): void {
    }

    confirm(): void {
        this.responsavelService
            .update(this.data).subscribe(
            () => {
                this.snackBarService.showSuccess('Resposável  adicionado com sucesso!');
                this.dialogRef.close(true);
            },
            error => {
                this.snackBarService.showError('Erro ao adicionar resposável.', error);
            }
        );
    }
}
