import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import  jwt_decode from 'jwt-decode'
import { UtilService } from 'app/main/core/services/util.service';

export interface IdentityTokenDetails {
    servidor: boolean;
    nome: string;
    gruposCamunda: Array<string>;    
}

export class IdentityToken {
    constructor(
        public sub?: string,
        public issuer?: string,
        public isuedAt?: Date,
        public expiresAt?: Date,
        public details?: IdentityTokenDetails,
        public roles?: any,
    ) {
    }

    public static decode(jwt: string): IdentityToken {
        if (jwt && jwt.length) {
            const other: any = jwt_decode(jwt);
            const instance = new IdentityToken(
                other.sub,
                other.iss,
                new Date(other.iat),
                new Date(other.exp),
                other.details,
                other.roles,
            );

            return instance;
        }

        return null;
    }
}

@Injectable({ 
    providedIn: 'root'
})
export class AuthenticatedUserService {
    constructor(
        private cookiesService: CookieService
    ) {

    }

    public get isAuthenticated(): boolean {
        const authorization: string = this.cookiesService.get(environment.IDENTITY_COOKIE_NAME);

        return authorization && authorization.length > 0;
    }

    public get identityTokenValue(): string {
        return this.isAuthenticated
            ? this.cookiesService.get(environment.IDENTITY_COOKIE_NAME)
            : '';
    }

    public get identityToken(): IdentityToken {
        if (this.isAuthenticated) {
            const jwt = this.cookiesService.get(environment.IDENTITY_COOKIE_NAME);

            return IdentityToken.decode(jwt);
        }

        return null;
    }

    /**
     * Objeto do jwt decodificado
     * @see environment.AUTHORIZATION_COOKIE_NAME
     * Exemplo: 
     * {
        "sub": "26694478135",
        "nbf": 1679060539,
        "roles": {
            "ADMINISTRADOR": [],
            "PERFIL TECNICO": [],
            "gruposCamunda": [
            "GrpRefendarCONSEMA",
            "GrpProcSurhAguaSubterranea",
            "grpGestManifestacaoCoordenadoriaControleHidricos",
            "GrpProcSurhResponsavelAtendimento",
            "GrpProcSurhSegurancaDeBarragem",
            "GrpProcSurhEmissorTitulos",
            "GrpProcSurhOutorga"
            ],
            "acesso asções de analista na SURH": [],
            "USUÁRIO PÚBLICO": []
        },
        "iss": "SEMA",
        "exp": 1679064139,
        "iat": 1679060539,
        "jti": "e0515ca2-9ce5-457b-9958-6279684e37b2"
        } 
     */
    public get identityValue(): IdentityToken {
        if (this.isAuthenticated) {
            const jwt = this.cookiesService.get(environment.AUTHORIZATION_COOKIE_NAME);
            return JSON.parse(atob(jwt.split('.')[1]));
        }
        return null;
    }

    /**
     * Verifica se usuário possui o papel informado
     * @param role Papel
     * @returns 
     */
    public hasRole(role: string): boolean {
        if (this.identityValue && this.identityValue.roles) {
            return Object.keys(this.identityValue.roles).map((r) => r.toUpperCase())
                .findIndex((r) => UtilService.strNormalizeNFD(r) == UtilService.strNormalizeNFD(role).toUpperCase()) != -1; 
        }
        return false;
    }

    /**
     * Verifica se o usuário possui o papel e se esse papel possui determinada permissao
     * @param role Papel
     * @param permission Permissoes do papel
     * @returns 
     */
    public hasRoleAndPermission(role: string="", permission: string=""): boolean {
        if (this.identityValue && this.identityValue.roles) {
            const theRoleName = Object.keys(this.identityValue.roles).map((r) => r.toUpperCase())
                .find((r) => r == UtilService.strNormalizeNFD(role).toUpperCase());
            if (theRoleName == null) return false;
            const theRolePermissions = (this.identityValue.roles[theRoleName] as Array<string>);
            if (theRolePermissions == null || theRolePermissions.length == 0) return false;
            return theRolePermissions.map(rp => UtilService.strNormalizeNFD(rp).toUpperCase()).find((rp) => rp == UtilService.strNormalizeNFD(permission).toUpperCase()) != null;
        }
        return false;
    }


}