import {NgModule} from '@angular/core';
import {PessoaFisicaListComponent} from './pessoa-fisica-list/pessoa-fisica-list.component';
import {PessoaFisicaDetailComponent} from './pessoa-fisica-detail/pessoa-fisica-detail.component';
import {RouterModule, Routes} from '@angular/router';
import {PessoaFisicaListResolver, PessoaFisicaResolver} from './pessoa-fisica.service';
import {SharedModule} from 'app/main/shared/shared.module';
import {CoreModule} from 'app/main/core/core.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";

import { PortalSecurityAuthGuardPerfilService } from '@sema-security-angular/portal-security';
import { DialogAjudaCtfComponent } from './dialog-ajuda-ctf/dialog-ajuda-ctf.component';


export const routes: Routes = [
    {
        path: 'new',
        component: PessoaFisicaDetailComponent,
        canActivate: [ PortalSecurityAuthGuardPerfilService ],
        data: {
            securityId: ['ee18b60d-e31e-47ff-b423-58cc59417ae5'],
            securityMessage: 'Acesso negado.'
        }
    },
    {
        path: 'pessoas-fisicas',
        component: PessoaFisicaListComponent,
        resolve: {
            data: PessoaFisicaListResolver
        }
    },
    {
        path: ':id/edit',
        component: PessoaFisicaDetailComponent,
        canActivate: [ PortalSecurityAuthGuardPerfilService],
        resolve: {
            data: PessoaFisicaResolver
        },
        data: {
            securityId: ['ee18b60d-e31e-47ff-b423-58cc59417ae5'],
            securityMessage: 'Acesso negado.'
        }
    },
    {
        path: ':id/detail',
        component: PessoaFisicaDetailComponent,
        resolve: {
            data: PessoaFisicaResolver
        }
    }
];

@NgModule({
    declarations: [PessoaFisicaListComponent, PessoaFisicaDetailComponent, DialogAjudaCtfComponent],
    imports: [CoreModule, SharedModule, RouterModule.forChild(routes), MatTooltipModule],
    entryComponents: [PessoaFisicaDetailComponent, PessoaFisicaListComponent, DialogAjudaCtfComponent],
    providers: [PessoaFisicaResolver, PessoaFisicaListResolver],
    exports: [DialogAjudaCtfComponent]
})
export class PessoasFisicasModule {
}
