import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';
import {CoreComponentsModule} from 'app/main/core/components/core-components.module';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from '../shared/shared.module';
import {DocumentosTemplateModule} from './documentos-template/documentos-template.module';
import {PessoaJuridicaModule} from './pessoa-juridica/pessoa-juridica.module';
import {PessoasFisicasModule} from './pessoas-fisicas/pessoas-fisicas.module';
import {PessoasEstrangeirasModule} from './pessoas-estrangeiras/pessoas-estrangeiras.module';
import {ResponsaveisTecnicoModule} from './responsaveis-tecnico/responsaveis-tecnico.module';
import {ServidoresModule} from './servidores/servidores.module';
import {GestoesModule} from './gestoes/gestao.module';
import {OrganizacoesAdministrativasModule} from './gestoes/organizacoes-administrativas/organizacao-administrativa.module';
import {TaxonomiaModule} from './taxonomia/taxonomia.module';
import {GruposDocumentoModule} from './grupos-documento/grupos-documento.module';
import { TipoCategoriaModule } from './tipo-categoria/tipo-categoria.module';
import { TipoEspecialidadeModule } from './tipo-especialidade/tipo-especialidade.module';

const routes: Routes = [

    {
        path: 'documentos-template',
        loadChildren: './documentos-template/documentos-template.module#DocumentosTemplateModule'
    },
    {
        path: 'grupos-documento',
        loadChildren: './grupos-documento/grupos-documento.module#GruposDocumentoModule'
    },
    {
        path: 'pessoas-fisicas',
        loadChildren: './pessoas-fisicas/pessoas-fisicas.module#PessoasFisicasModule'
    },
    {
        path: 'pessoas-estrangeiras',
        loadChildren: './pessoas-estrangeiras/pessoas-estrangeiras.module#PessoasEstrangeirasModule'
    },
    {
        path: 'pessoas-juridicas',
        loadChildren: './pessoa-juridica/pessoa-juridica.module#PessoaJuridicaModule'
    },
    {
        path: 'responsaveis-tecnicos',
        loadChildren: './responsaveis-tecnico/responsaveis-tecnico.module#ResponsaveisTecnicoModule'
    },
    {
        path: 'servidores',
        loadChildren: './servidores/servidores.module#ServidoresModule'
    },
    {
        path: 'gestoes',
        loadChildren: './gestoes/gestao.module#GestoesModule'
    },
    {
        path: 'organizacoes-administrativas',
        loadChildren: './gestoes/organizacoes-administrativas/organizacao-administrativa.module#OrganizacoesAdministrativasModule'
    },
    {
        path: 'taxonomias',
        loadChildren: './taxonomia/taxonomia.module#TaxonomiaModule'
    },
    {
        path: 'configuracao-avisos-automatico',
        loadChildren: './configuracao-avisos-automatico/configuracao-avisos-automatico.module#ConfiguracaoAvisosAutomaticoModule',
    },
    {
        path: 'aviso-imediato',
        loadChildren: './aviso-imediato/aviso-imediato.module#AvisoImediatoModule',
    },
    {
        path: 'tipo-categoria',
        loadChildren: './tipo-categoria/tipo-categoria.module#TipoCategoriaModule',
    },
    {
        path: 'tipo-especialidade',
        loadChildren: './tipo-especialidade/tipo-especialidade.module#TipoEspecialidadeModule',
    },
];

@NgModule({
    declarations: [],
    imports: [
        // Angular Core
        CommonModule,
        RouterModule.forChild(routes),

        // Fuse
        FuseSharedModule,

        CoreModule.forRoot(),
        CoreComponentsModule,
        DocumentosTemplateModule,
        GruposDocumentoModule,
        PessoasFisicasModule,
        PessoasEstrangeirasModule,
        PessoaJuridicaModule,
        ResponsaveisTecnicoModule,
        ServidoresModule,
        GestoesModule,
        OrganizacoesAdministrativasModule,
        TaxonomiaModule,
        SharedModule,
        TipoCategoriaModule,
        TipoEspecialidadeModule
    ]
})
export class PagesModule {
}
