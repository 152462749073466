import {Injectable, Injector} from '@angular/core';
import {PessoaEstrangeira} from './pessoa-estrangeira.model';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class PessoaEstrangeiraListResolver extends ListResolver<PessoaEstrangeira> {
    constructor(service: PessoaEstrangeiraService) {
        super(service);
    }
}

@Injectable()
export class PessoaEstrangeiraResolver extends DetailResolver<PessoaEstrangeira> {
    constructor(
        service: PessoaEstrangeiraService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class PessoaEstrangeiraService extends BaseService<PessoaEstrangeira> {
    constructor(protected injector: Injector) {
        super(
            '/api/pessoas-estrangeiras',
            injector,
            PessoaEstrangeira.prototype,
            PessoaEstrangeira.fromJson
        );
    }

    getByPassaporte(passaporte: string): Observable<PessoaEstrangeira> {
        const url = `${this.urlResource}/passaporte/${passaporte}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getByPassaporteAndCadastroCompleto(passaporte: string): Observable<PessoaEstrangeira> {
        const url = `${this.urlResource}/passaporte/${passaporte}/cadastro-completo`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getByPessoaId(pessoaId: number): Observable<PessoaEstrangeira> {
        const url = `${this.urlResource}/pessoaId/${pessoaId}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
