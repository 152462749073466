import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { TipoVinculo } from 'app/main/pages/servidores/servidor.model';

@Injectable({
    providedIn: 'root'
})
export class TipoVinculoService extends BaseService<TipoVinculo> {
    constructor(protected injector: Injector) {
        super('/api/tipo-vinculo', injector, TipoVinculo.prototype, TipoVinculo.fromJson);
    }
}
