import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'app/main/shared/shared.module';
import { CoreModule } from './../../core/core.module';
import { CommonModule } from '@angular/common';
import { TipoEspecialidadeComponent } from './tipo-especialidade/tipo-especialidade.component';
import { TipoEspecialidadeListComponent } from './tipo-especialidade-list/tipo-especialidade-list.component';
import { TipoEspecialidadeListResolver, TipoEspecialidadeResolver } from './tipo-especialidade.service';

export const routes: Routes = [
  {
      path: 'new',
      component: TipoEspecialidadeComponent,
  },
  {
      path: '',
      component: TipoEspecialidadeListComponent,
      resolve: {
          data: TipoEspecialidadeListResolver,
      },
  },
  {
      path: ':id/edit',
      component: TipoEspecialidadeComponent,
      resolve: {
          data: TipoEspecialidadeResolver,
      },
  },
  {
      path: ':id/detail',
      component: TipoEspecialidadeComponent,
      resolve: {
          data: TipoEspecialidadeResolver,
      },
  },
];



@NgModule({
  declarations: [TipoEspecialidadeComponent, TipoEspecialidadeListComponent],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  entryComponents: [TipoEspecialidadeComponent, TipoEspecialidadeListComponent],
  providers: [TipoEspecialidadeResolver, TipoEspecialidadeListResolver],
})
export class TipoEspecialidadeModule { }
