import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatTabChangeEvent} from '@angular/material';
import {fuseAnimations} from '@fuse/animations';
import {AuthenticationService} from 'app/main/core/services/authentication.service';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {ConfirmarExclusaoDialogComponent} from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {Cargo} from './../../cargos/cargo.model';
import {CargoService} from './../../cargos/cargo.service';
import {CoreMessageService} from 'app/main/core/services/core-message.service';
import {OrganizacaoAdministrativaService} from '../../gestoes/organizacoes-administrativas/organizacao-administrativa.service';
import {OrganizacaoAdministrativa} from '../../gestoes/organizacoes-administrativas/organizacao-administrativa.model';
import {PessoaFisicaService} from '../../pessoas-fisicas/pessoa-fisica.service';
import {PerfilSeap, Servidor, ServidorEvento, ServidorLotacao, TipoVinculo} from '../servidor.model';
import {ServidorService} from '../servidor.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import { PessoaFisica } from '../../pessoas-fisicas/pessoa-fisica.model';
import { PerfilSeapService } from 'app/main/shared/services/perfil-seap.service';
import { TipoVinculoService } from 'app/main/shared/services/tipo-vinculo.service';
import { Lotacao } from '../lotacao.model';
import { FuncaoServidor } from '../funcao-servidor.model';
import { FuncaoServidorService } from 'app/main/shared/services/funcao-servidor.service';
import { DialogServidorEventoComponent } from './dialog-servidor-evento/dialog-servidor-evento-component';
import { TipoEspecialidadeService } from '../../tipo-especialidade/tipo-especialidade.service'; 
import { TipoCategoriaService } from '../../tipo-categoria/tipo-categoria.service';
import { TipoEspecialidade } from '../../tipo-especialidade/tipo-especialidade.model';
import { TipoCategoria } from '../../tipo-categoria/tipo-categoria.model';

@Component({
    selector: 'app-servidor-detail',
    templateUrl: './servidor-detail.component.html',
    styleUrls: ['./servidor-detail.component.scss'],
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None
})
export class ServidorDetailComponent extends BaseDetailComponent<Servidor> implements OnInit {
    cargos: Array<Cargo>;
    funcoes: Array<FuncaoServidor>;
    perfisSeap: Array<PerfilSeap>;
    tiposVinculos: Array<TipoVinculo>;
    tiposEspecialidades: Array<TipoEspecialidade>;
    tiposCategorias: Array<TipoCategoria>;
    pessoaFisica: PessoaFisica;
    organizacoesAdministrativas: Array<OrganizacaoAdministrativa>;
    lotacoes: MatTableDataSource<ServidorLotacao> = new MatTableDataSource<ServidorLotacao>();
    eventos: MatTableDataSource<ServidorEvento> = new MatTableDataSource<ServidorEvento>();
    isEditandoEvento: boolean = false;
    isNovoServidor: boolean = false;
    lotacoesColumns: string[] = ['id', 'organizacaoNome', 'funcao', 'dataInicio', 'dataFim', 'acoes'];
    eventosColumns: string[] = ['id', 'tipoLicenca', 'dataInicio', 'dataFim', 'reduCargHoraria', 'bloqueado', 'acoes'];
    compareCargos = (a: any, b: any) => a && b && (a === b || a.descricao === b || b.descricao === a || a.id === b || b.id === a);
    compareCategorias = (a: any, b: any) => a && b && (a === b || a.descricao === b || b.descricao === a || a.id === b || b.id === a);
    compareEspecialidades = (a: any, b: any) => a && b && (a === b || a.descricao === b || b.descricao === a || a.id === b || b.id === a);
    compareOrganizacoesAdministrativas = (a: any, b: any) => a && b && (a === b || a.nome === b || b.nome === a || a.id === b || b.id === a);

    constructor(
        protected injector: Injector,
        protected servidorService: ServidorService,
        protected cargoService: CargoService,
        protected funcaoServidorService: FuncaoServidorService,
        protected perfilSeapService: PerfilSeapService,
        protected tipoVinculoService: TipoVinculoService,
        protected tipoEspecialidadeService: TipoEspecialidadeService,
        protected tipoCategoriaService: TipoCategoriaService,
        protected pessoaFisicaService: PessoaFisicaService,
        protected organizacaoAdministrativaService: OrganizacaoAdministrativaService,
    ) {
        super(
            injector,
            new Servidor(),
            servidorService,
            Servidor.fromJson
        );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.loadFuncoes();
        this.loadPerfisSeap();
        this.loadTiposVinculos();
        this.loadTiposEspecialidades();
        this.loadTiposCategorias();
        this.loadCargos();
        this.loadOrganizacoesAdministrativas();
        if (this.currentAction === 'edit') {
            this.pesquisar();
        }
    }

    get cargoId(): number {
        return this.form.get('cargoId').value;
    }

    get cpf(): string {
        const value: string = this.form.get('cpf').value;
        return value.match(/\d+/g).join('');
    }

    get id(): number {
        return this.resource && this.resource.id ? this.resource.id : 0;
    }

    get informacoesServidorDisabled(): boolean {
        return this.isSomenteVisualizacao || (this.isSomenteCriacao && this.id !== 0) 
            || (this.isSomenteEdicao && this.id === 0);
    }

    get isSomenteCriacao(): boolean {
        return this.currentAction === 'new';
    }

    get isSomenteEdicao(): boolean {
        return this.currentAction === 'edit';
    }

    get matricula(): string {
        return this.form.get('matricula').value;
    }

    get pessoaFisicaId(): number {
        return this.form.get('pessoaFisicaId').value;
    }

    get emailServidor(): string {
        return this.form.get('emailServidor').value;
    }

    get salvarDisabled(): boolean {
        return this.form.invalid || this.form.pristine || 
            this.isSomenteVisualizacao || (this.isSomenteCriacao && this.id !== 0) || 
            (this.isSomenteEdicao && this.id === 0);
    }

    get lotacaoDisabled(): boolean {
        return this.isSomenteVisualizacao || this.lotacaoOrganizacaoAdministrativaId < 1;
    }

    get lotacaoFuncaoId(): number {
        return this.form.get('lotacaoFuncaoId').value;
    }

    get lotacaoOrganizacaoAdministrativaId(): number {
        return this.form.get('lotacaoOrganizacaoAdministrativaId').value;
    }

    get lotacaoTabDisabled(): boolean {
        return this.id < 1;
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            cpf: [null, [Validators.required, Validators.pattern(/^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/)]],
            rg: [null],
            matricula: [null],
            nome: [null],
            dataNascimento: [null],
            profissoes: [null],
            emails: [null],
            telefones: [null],
            cargoId: [null, [Validators.required]],
            funcaoServidorId: [null],
            perfilSeapId: [null, [Validators.required]],
            tipoVinculoId: [null, [Validators.required]],
            tipoEspecialidadeId: [null, [Validators.required]],
            tipoCategoriaId: [null, [Validators.required]],
            codUnidade: [null],
            unidade: [null],
            superintendencia: [null],
            pessoaFisicaId: [null],
            lotacaoFuncaoId: [null],
            lotacaoOrganizacaoAdministrativaId: [null],
            emailServidor: [null, [Validators.required]],
            

            /* Evento */
            eventoId: [null],
            eventoDescricao: [null],
            tipoLicenca: [null],
            dataInicioEvento: [null],
            dataFimEvento: [null],
            indReducaoCargaHoraria: [false],
            indBloqueado: [true]
        });
    }

    excluirLotacaoDialog(lotacao: ServidorLotacao): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {
                label: lotacao.id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirLotacao(lotacao);
            }
        });
    }

    excluirEventoDialog(evento: ServidorEvento): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {
                label: evento.id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirEvento(evento);
            }
        });
    }

    private excluirLotacao(lotacao: ServidorLotacao): void {
        const onSuccess = () => {
            this.snackBarService.showSuccess('Lotação excluída com sucesso.');

            this.loadLotacoes();
        };

        this.servidorService
            .deleteLotacao(lotacao)
            .subscribe(
                _ =>  onSuccess(),
                error => this.snackBarService.showError(error)
            );
    }

    private excluirEvento(evento: ServidorEvento): void {
        const onSuccess = () => {
            //this.snackBarService.showSuccess('Evento excluído com sucesso.');

            this.loadEventos();
        };

        this.servidorService
            .deleteEvento(evento)
            .subscribe(
                _ =>  onSuccess(),
                error => this.snackBarService.showError(error)
            );
    }

    private loadCargos(): void {
        this.cargoService
            .getAll()
            .subscribe(cargos => this.cargos = cargos);
    }

    private loadFuncoes(): void {
        this.funcaoServidorService
            .getAll()
            .subscribe(funcoes => this.funcoes = funcoes);
    }

    private loadPerfisSeap(): void {
        this.perfilSeapService
            .getAll()
            .subscribe(perfisSeap => this.perfisSeap = perfisSeap);
    }

    private loadTiposVinculos(): void {
        this.tipoVinculoService
            .getAll()
            .subscribe(tiposVinculos => this.tiposVinculos = tiposVinculos);
    }

    private loadTiposEspecialidades(): void {
        this.tipoEspecialidadeService
            .getAll()
            .subscribe(tiposEspecialidades => this.tiposEspecialidades = tiposEspecialidades);
    }

    private loadTiposCategorias(): void {
        this.tipoCategoriaService
            .getAll()
            .subscribe(tiposCategorias => this.tiposCategorias = tiposCategorias);
    }

    private loadOrganizacoesAdministrativas(): void {
        this.organizacaoAdministrativaService
            .getAll()
            .subscribe(organizacoesAdministrativas => this.organizacoesAdministrativas = organizacoesAdministrativas);
    }

    private loadLotacoes(): void {
        if (this.id) {
            this.servidorService
                .getLotacoes(this.resource)
                .subscribe(lotacoes => this.lotacoes.data = lotacoes);
        }
    }

    private loadEventos(): void {
        if (this.id) {
            this.servidorService
                .getEventos(this.resource)
                .subscribe(eventos => this.eventos.data = eventos);
        }
    }

    pesquisar(): void {
        if (this.currentAction === 'new' && this.cpf.length) {
            this.getPessoaFisica(this.cpf)
        } else {
            this.servidorService
                .getByCpf(this.cpf)
                .subscribe(response => this.form.patchValue(response));
        }
    }

    private getPessoaFisica(cpf: string): void {
        this.pessoaFisicaService
            .getByCpf(cpf)
            .subscribe(res => {
                const patchValue = (response: any) => {
                    this.resource = Servidor.fromJson(response);
                    this.form.patchValue(this.resource);
                };
                patchValue(res);
                //TODO -  Serviço SEAP não disponível
                /* this.servidorService
                    .getSeapByCpf(this.cpf)
                    .subscribe(response => {
                        patchValue(response);
                        this.actionsForSuccess(this.resource);
                }, e => {
                    if (e.status === 400) {
                        this.servidorService.getSeapByCpf(this.cpf).subscribe(
                            response => {
                                patchValue(response);
                            }, e => this.snackBarService.showError(e.error.errors[0] === '' ? e.error.message : e.error.errors[0]));
                            this.isNovoServidor = true;
                            this.form.patchValue({pessoaFisicaId: res.id});
                    }
                }); */
            }, e => {
                if (e.status === 400) {
                    this.snackBarService.showAlert(
                        "Favor solicitar ao servidor que realize seu cadastro através do Portal do SIGA."
                    );
                    this.voltar();
                }
            });
    }

    openDialogServidorEvento(evento: ServidorEvento): void {
        evento.servidor = this.resource;
        this.dialog.open(DialogServidorEventoComponent, {
                width: '1800px',
                maxHeight: '535px',
                data: evento
            }
        ).afterClosed().subscribe(result => {
            if (result) {
                this.loadEventos();
            }
        });
    }
    
    vincularServidor(): void {
        if (this.id && this.lotacaoOrganizacaoAdministrativaId) {
            const lotacao: ServidorLotacao = {
                funcaoServidorId: this.lotacaoFuncaoId,
                organizacaoId: this.lotacaoOrganizacaoAdministrativaId
            };

            this.servidorService
                .createLotacao(this.resource, lotacao)
                .subscribe(
                    _ => this.loadLotacoes(),
                    error => this.snackBarService.showError(error)
                );
        }
    }

    onCpfKeyDown(event: KeyboardEvent): void {
        if (event.keyCode === 13) {
            event.preventDefault();

            this.pesquisar();
        }
    }

    onSelectedTabChange(event: MatTabChangeEvent): void {
        if (event.index === 1) { // "Lotações"
            this.loadLotacoes();
        }
        if (event.index === 2) { // "Eventos"
            this.isEditandoEvento = false;
            this.loadEventos();
        }
    }


    protected creationPageTitle(): string {
        return 'Cadastro de novo Servidor';
    }

    protected editionPageTitle(): string {
        return `Editando o Servidor: ${this.resource.nome}`;
    }

    protected visualizationPageTitle(): string {
        return `Visualizando o Servidor: ${this.resource.nome}`;
    }

    protected actionsForSuccess(): void {
        this.snackBarService.showSuccess('Solicitação processada com sucesso!');

        this.router
            .navigateByUrl(this.getBaseComponetPath(), {
                skipLocationChange: true
            })           
    }
}
