import {BaseModel} from 'app/main/shared/models/base.model';

export class FuncaoServidor extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string
    ) {
        super();
    }

    static fromJson(json: any): FuncaoServidor {
        json = json || {};

        return new FuncaoServidor(
            json.id,
            json.descricao
        );
    }

    static fromIterable(json: any): FuncaoServidor[] {
        const funcaoServidor: FuncaoServidor[] = [];

        if (json && typeof json[Symbol.iterator] === 'function') {
            for (const value of json) {
                if (value) {
                    funcaoServidor.push(FuncaoServidor.fromJson(value));
                }
            }
        }

        return funcaoServidor;
    }
}


