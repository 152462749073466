import { ResponsavelTecnico } from 'app/main/pages/responsaveis-tecnico/responsavel-tecnico.model';
import { BaseModel } from '../../models/base.model';

export class HistoricoAlteracaoRtDTO extends BaseModel {
    constructor(
        public id?: number,
        public habilitado?: boolean,
        public justificativa?: string,
        public tituloDocumentoAnexo?: string
    ) {
        super();
    }

    static fromJson(json: any): HistoricoAlteracaoRtDTO {
        if (json === undefined || json === null) {
            return null;
        }

        return new HistoricoAlteracaoRtDTO(
            json.id,
            json.justificativa,
            json.habilitado,
            json.tituloDocumentoAnexo        
        );
    }
} 
